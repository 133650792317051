import * as React from 'react';
import AppBar from "../../appbar/AppBar";
import {AppWithFooter} from "../../../App";
import {useNavigate} from "react-router-dom";
import "./PrivacyPage.css";
import {ARCButton} from "../../ui-components/design-system/ARCButton";
import { getLocalizedString } from '../../../util/Localization';
export const PrivacyPage = () => {
    const navigate = useNavigate();
    
    const backButtonPress = React.useCallback(() => {
        navigate(-1);
    },[navigate]);

    return (
     <AppWithFooter>
         <AppBar title={<></>} right={!localStorage.getItem("token") ? <div className={'privacy-login-text'} onClick={() => navigate('/login')}>{'Log In'}</div> : undefined} logoNonInteractive={true}/>
         <main className={'o-main'}>
            <div className={'l-wrap l-container--lg'}>
                <div className={'privacy__header'}>
                    <div className={'o-heading--l u-font-weight--700'}>
                        {'ARC Client Portal Privacy Policy - United States'}
                    </div>
                    <ARCButton
                        fill={'filled'}
                        variant={'primary'}
                        size={'md'}
                        id="privacy-back-top"
                        onClick={backButtonPress}
                        label={getLocalizedString('back', 'Back')}
                    >
                    </ARCButton>
                </div>
                <div className={'l-container--md o-rich-text'}>
                    <p>
                        <span className={'o-body-text'}>This Privacy Policy describes the types of personal information ChargeitSpot LLC (&ldquo;ChargeItSpot&rdquo; or &ldquo;ARC&rdquo;) and its corporate affiliates (&ldquo;we&rdquo;, &ldquo;us&rdquo;, &ldquo;our&rdquo;) collects about users (&ldquo;User&rdquo;, &ldquo;you&rdquo;, or &ldquo;your&rdquo;) on this website (the &ldquo;Website&rdquo;), how we use the information, and with whom we may share it.</span>
                    </p>
                    <p>
                        <span className={'o-body-text'}>By accessing or using the Website, you agree to the terms of this Privacy Policy. Please note that ARC only collects what you, your employer or another entity that you have a business relation with, voluntarily provides to us.</span>
                    </p>
                    <ol>
                        <li>
                            <span className={'o-body-text'}>PERSONAL INFORMATION WE COLLECT</span>
                            <ol type={'a'}>
                                <li>
                                    <span className={'o-body-text'}>Customer Data &ndash; When you interact with us via our Website or other systems, we may collect personal information. ARC may record and use information generated by the use of ARC Devices (&ldquo;Data&rdquo;), including first and last names, employee identification numbers, ARC Device serial numbers, check in/out information, email addresses, ARC Device issue reporting data, number of users per day, user photographs (used for security purposes), and other applicable data. We collect this information when you submit web forms, interact with our website, you use ARC kiosks, or we provide a service. </span>
                                </li>
                                <li>
                                    <span className={'o-body-text'}>Usage Information &ndash; We may also collect usage information on how you interact with our Website. This may include cookies and similar technologies. This Usage Information may include:</span>
                                </li>
                                <ul style={{listStyleType: 'circle'}}>
                                    <li><span className={'o-body-text'}>Your device serial number or other unique identifier (&ldquo;Device Identifier&rdquo;). A Device Identifier is a number that is automatically assigned to your Device used to access this Website, and our computers identify your Device by its Device Identifier;</span>
                                    </li>
                                    <li><span className={'o-body-text'}>Details of your visits to our Website, including pages visited, traffic data, logs, referral sources, search terms, and other communication data and the resources that you access and use on the Website.</span>
                                    </li>
                                    <li><span className={'o-body-text'}>The areas within this Website that you visit and your activities there, including remembering you and your preferences;</span>
                                    </li>
                                    <li><span className={'o-body-text'}>Certain other Device data, including the date and time of day of your visit, among other information.</span>
                                    </li>           
                                </ul>
                                <span className={'o-body-text'}>When you access this Website by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device you use, your mobile device&rsquo;s unique ID, the IP address of your mobile device, your mobile operating system, the type of mobile Internet browser you use, unique device identifiers and other diagnostic data.</span>
                            </ol>
                        </li>
                        <li>
                            <span className={'o-body-text'}>HOW WE USE PERSONAL INFORMATION</span>
                            <p><span className={'o-body-text'}>We use your personal information to provide our products or services to you or a business that you are associated with. In compliance with regulatory practices and confidentiality agreements, we may use your personal information to: </span></p>
                            <ul>
                                <li><span className={'o-body-text'}>Communicate with you about our products and services;</span></li>
                                <li><span
                                >Improve, develop, maintain and update our products and services;</span></li>
                                <li><span
                                >Secure and protect our products, services, Website and business;</span></li>
                                <li><span className={'o-body-text'}>For data analysis, identifying usage trends and aggregating or de-identifying information for analytics and reporting purposes;</span>
                                </li>
                                <li><span className={'o-body-text'}>Manage and develop business operations; </span></li>
                                <li><span className={'o-body-text'}>For any other purpose with your consent, or</span></li>
                                <li><span className={'o-body-text'}>Meet our legal and regulatory requirements.</span></li>
                            </ul>
                        </li>
                        <li>
                            <span className={'o-body-text'}>HOW WE SHARE PERSONAL INFORMATION</span>
                            <ol type={'a'}>
                                <li>
                                    <span className={'o-body-text'}>Service Providers &ndash; We may share personal information with third-party service providers to support our websites, products and services.</span>
                                </li>
                                <li>
                                    <span className={'o-body-text'}>Aggregated or De-identified Data &ndash; We may disclose or use aggregated or de-identified personal information for any purpose. For example, we may share aggregated or de-identified personal information with prospects or partners for business or research.</span>
                                </li>
                                <li>
                                    <span className={'o-body-text'}>Legal Compliance &ndash; We may disclose your information if required by law, to protect our rights or safety, or in response to a valid legal request. We do not sell, rent, exchange, or otherwise disclose any information that we collect about customers, except in the case of a malicious attack or as required by law.</span>
                                </li>
                                <li>
                                    <span className={'o-body-text'}>Business Transactions &ndash; We may disclose your information to a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Information held by us is among the assets transferred.</span>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <span className={'o-body-text'}>DATA SECURITY</span>
                            <p><span className={'o-body-text'}>ARC shall maintain reasonable administrative, physical, and technical safeguards, consistent with generally accepted practices in ARC&rsquo;s industry, designed to protect personal information to the extent it includes Customer Confidential Information or personally identifiable information of Customer&rsquo;s users (collectively, &ldquo;</span><span
                                    >Customer Data</span><span className={'o-body-text'}>&rdquo;). While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security. Unfortunately, no method of transmitting information over the internet or storing it electronically is 100% secure. Neither we nor anyone else can&mdash;and we do not&mdash;guarantee the confidentiality, security, integrity, or availability of any Personal Information transmitted to or from us over the Internet and/or stored electronically. In all situations, you should carefully consider whether and how to provide Personal Information to someone else (including us). All transmission of Personal Information is at your own risk.</span>
                            </p>
                            <p><span className={'o-body-text'}>ARC ensures that all such safeguards comply with data protection and privacy laws and regulations that are applicable in ARC&rsquo;s geographic territory to its activities. ARC will notify users through their affiliated ARC Customer of any breach of Customer Data within 72 hours, or as soon as reasonably practicable, after ARC&rsquo;s confirmation of the breach. However, no method of transmission over the internet or electronic storage is entirely secure, and we cannot guarantee absolute security.</span>
                            </p>
                        </li>
                        <li>
                            <span className={'o-body-text'}>DATA RETENTION</span>
                            <p><span className={'o-body-text'}>ARC will retain Customer Data in accordance with a Customer&#39;s instructions (including to perform any applicable terms in the Customer Agreement), as long as the business has an active contract with ARC, and as required by applicable law. </span>
                            </p>
                        </li>
                        <li>
                            <span className={'o-body-text'}>AGE LIMITATIONS</span>
                            <p><span className={'o-body-text'}>The Website is not intended for individuals under the age of 18. We do not knowingly collect personal information from children under 18. If you are a parent or guardian and believe we have collected information from a child, please contact us at </span><span
                            ><a href="mailto:privacy@chargeitspot.com">privacy@chargeitspot.com</a></span><span
                            >, and we will take appropriate steps to remove the information.</span></p>
                        </li>
                        <li>
                            <span className={'o-body-text'}>CHANGES TO THIS PRIVACY POLICY</span>
                            <p><span className={'o-body-text'}>ARC may update this statement from time to time, which will be effective as soon as it is posted. The most current version will always be posted on this Website. By continuing to use this Website after we make changes, you will be deemed to have agreed to the changes.</span>
                            </p>
                        </li>
                        <li>
                            <span className={'o-body-text'}>ACCURACY AND CORRECTION</span>
                            <p><span className={'o-body-text'}>You may update and edit your name and email address directly on this Website.</span></p>
                            <p>
                                <span className={'o-body-text'}>Please contact the Data Privacy Officer with any questions or concerns at </span>
                                <span className={'o-body-text'}>
                                    <a href="mailto:privacy@chargeitspot.com">privacy@chargeitspot.com</a>
                                </span>
                                <span className={'o-body-text'}>. </span>
                            </p>
                        </li>
                        <li>
                            <span className={'o-body-text'}>DATA PROTECTION OFFICER</span>
                            <p><span className={'o-body-text'}>If you have any questions about this Privacy Policy or our privacy practices please contact our Data Protection Officer at </span><span
                                    ><a href="mailto:privacy@chargeitspot.com">privacy@chargeitspot.com</a></span><span
                            >.</span></p>
                        </li>
                    </ol>
                    <p><span className={'o-body-text'}>By using this Website, you acknowledge that you have read, understood, and agree to the terms of this Privacy Policy.</span>
                    </p>
                </div>
                <div className={'privacy-back-button-div'}>
                    <ARCButton
                        fill={'filled'}
                        variant={'primary'}
                        size={'md'}
                        id="privacy-back-bottom"
                        onClick={backButtonPress}
                        label={getLocalizedString('back', 'Back')}
                    >
                    </ARCButton>
                </div>
             </div>
         </main>
     </AppWithFooter>
    )
}
