import * as React from 'react';
import "../../../css/Footer.css"
import {ARCLogo} from "../ARCLogo";
import {getLocalizedString} from "../../../util/Localization";
import ARC_LOGO from "../../../assets/img/ARC_Logo.svg";
import {ARCBanner} from "./ARCBanner";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {appContextActions, appContextSelector, locationsForBrandSelector} from "../../app-context/appContextSlice";
import { useLocation } from 'react-router-dom';
import {UserProfile} from "../../appbar/prefabs/UserProfile";
import {env} from "../../../dtos/API_DOMAINS";
import {LocaleSelect} from "../LocaleSelect";

type ARCHeaderProps = {
    left?: React.ReactNode,
    right?: React.ReactNode,
    title?: React.ReactNode,
    className?: string,
    static?: boolean,
    logoOnClick?: () => void
};

export const ARCHeader: React.FC<ARCHeaderProps> = (props) => {
    const context = useAppSelector(appContextSelector);
    const location = useLocation();
    const dispatch = useAppDispatch();
    const locations = useAppSelector(locationsForBrandSelector(context.currentBrand?.brandId ?? 0));
    
    const hideGlobalMessage = React.useCallback((portalGlobalMessageId: number) => {
        return (context.dismissedMessages && portalGlobalMessageId && context.dismissedMessages[portalGlobalMessageId]);
    }, [context.dismissedMessages]);
    
    const headerTitle = React.useMemo(() => {
        if (props.title) return props.title;
        if (context.demoMode && location.pathname.includes('reports')) {
            return getLocalizedString('appbar.demoTitle', 'XYZ Corp.')
        } else {
            if (context.currentBrand?.brandName) {
                if (context.authorizationDto && locations.length === 1) {
                    return context.currentBrand?.brandName + ' - ' + context.currentLocation?.locationName;
                } else {
                    return context.currentBrand?.brandName;
                }
            }
        }
        return ''
    }, [props.title, context.demoMode, location.pathname, context.currentBrand?.brandName, context.authorizationDto, locations, context.currentLocation?.locationName])

    return (
        <header className={'o-header'}>
            {env === 'qa' &&
            <ARCBanner size={'sm'} message={'QA SITE'} accentedMessage hideIcon/>
            }

            <div className={'o-header__container'}>
                <div className={'l-wrap l-container--lg u-display--flex u-justify-content--space-between u-align-items--center'}>
                    <div className={'o-header__grid--3--left'}>
                        {props.left ? props.left :
                            <>
                                <ARCLogo
                                    onClick={props.logoOnClick}
                                    logoSrc={ARC_LOGO}
                                />
                                <h1 className={'o-header--l'}> {getLocalizedString('title', 'Client Portal')}</h1>
                            </>
                        }
                    </div>

                    <div className={'o-header__title o-header__grid--3--center'} onClick={() => {}}>
                        {headerTitle}
                    </div>

                    <div className={'o-header__grid--3--right'}>
                        {props.right ? props.right :
                            <div className={'u-display--flex u-align-items--center'}>
                                <div className={'u-hide-before--m u-space-right'}>
                                    <LocaleSelect/>
                                </div>

                                <UserProfile firstname={context.authorizationDto?.firstName} lastname={context.authorizationDto?.lastName}/>
                            </div>
                        }
                    </div>
                </div>
            </div>

            <section className={'o-header__section c-broadcast'}>
                {context.portalGlobalMessages &&
                    context.portalGlobalMessages.map((globalMessage, idx) => {
                        return (
                            <div key={idx}>
                                {!hideGlobalMessage(globalMessage.portalGlobalMessageId) &&
                                    <ARCBanner size={'md'} message={globalMessage.message} onClose={() => {dispatch(appContextActions.dismissPortalGlobalMessage(globalMessage.portalGlobalMessageId))}}/>
                                }
                            </div>
                        )
                    })
                }
            </section>
        </header>
    )
};
