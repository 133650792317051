import * as React from 'react';
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {appContextSelector} from "../../app-context/appContextSlice";
import {
    deviceContextActions,
    deviceContextSelector,
    getDeviceForEdit,
    reactivateDevice, updateDuplicateList
} from "../deviceSlice";
import {DEV_STATUS_INACTIVE, DeviceDto} from "../../../dtos/Device";
import AppBar from "../../appbar/AppBar";
import {SideBar} from "../../sidebar/SideBar";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {FormEvent} from "react";
import {AppWithFooter} from "../../../App";
import {ErrorMessageSectionItem} from "../../ui-components/StyledComponents";
import {selectLocationThunk} from "../../app-context/thunks/SelectLocationThunk";
import {LocationState} from "../../../@types/types";
import {ServerMessage} from "../../ui-components/ServerMessage";
import {Button, IconButton, styled} from "@material-ui/core";
import {ArrowBack} from "@material-ui/icons";
import "../../../css/ReactivateDevice.css"
import "../../../css/index.css"
import {calculateResponsiveSizeWithMinMax} from "../../../util/TextUtils";
import {getLocalizedString} from "../../../util/Localization";

const useAutoSelectLocationByUrl = () => {
    const context = useAppSelector(appContextSelector);
    const dispatch = useAppDispatch();
    const {locationId, deviceId} = useParams();

    React.useEffect(() => {
        if(locationId && context.currentLocation?.locationId !== parseInt(locationId)) {
            dispatch(selectLocationThunk(parseInt(locationId)));

            if(deviceId) {
                dispatch(getDeviceForEdit({locationId: parseInt(locationId), deviceId: parseInt(deviceId)}));
            }
        }
        else if(deviceId && context.currentLocation?.locationId) {
            dispatch(getDeviceForEdit({locationId: context.currentLocation.locationId, deviceId: parseInt(deviceId)}));
        }

    }, [context.currentLocation?.locationId, dispatch, deviceId, locationId]);
}

export const ReactivateDevice = () => {
    useAutoSelectLocationByUrl();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const context = useAppSelector(appContextSelector);
    const deviceContext = useAppSelector(deviceContextSelector);

    const [formDevice, setFormDevice] = React.useState<DeviceDto>({
        deviceId: undefined,
        deviceTag: '',
        deviceIdNumber: '',
        status: DEV_STATUS_INACTIVE,
        locationId: undefined,
        inactiveReason: '',
        inactiveReasonDetail: '',
        deviceType: undefined
    });

    const [navBackDeviceId, setNavBackDeviceId] = React.useState<number | undefined>(undefined);

    const {state} = useLocation() as LocationState;
    React.useEffect(() => {
        if(state) {
            const { editingFromDeviceId } = state; // Read values passed on state
            if(editingFromDeviceId) {
                setNavBackDeviceId(editingFromDeviceId);
            }
        }
    }, [state]);

    React.useEffect(() => {
        return () => {dispatch(deviceContextActions.resetReactivateStatus())}
    }, [dispatch]);

    const stateDevice = deviceContext.selectedDevice;

    React.useEffect(() => {
        if(stateDevice) {
            setFormDevice(stateDevice);
        }
    }, [stateDevice]);

    const isFormValid = () => {
        //validate

        return true;
    };
    
    const deviceDeactivationReasons = React.useMemo(() => {
        let returnString = formDevice.inactiveReason;
        returnString += formDevice.inactiveRmaReasons ? ', ' + formDevice.inactiveRmaReasons : '';
        return returnString;
    }, [formDevice.inactiveReason, formDevice.inactiveRmaReasons])
    
    const deviceDeactivationDetail = React.useMemo(() => {
        return formDevice.inactiveReasonDetail;
    }, [formDevice.inactiveReasonDetail])
    
    const deviceDeactivationSource = React.useMemo(() => {
        return formDevice.deactivationUser ? formDevice.deactivationUser + ' on ' + formDevice.deactivationDate : formDevice.deactivationDate;
    }, [formDevice.deactivationUser, formDevice.deactivationDate])

    const submitForm = React.useCallback((event : FormEvent) => {
        event.preventDefault();
        if(isFormValid()) {
            if(context.currentLocation?.locationId && formDevice.deviceId) {
                dispatch(reactivateDevice({deviceId: formDevice.deviceId, locationId: context.currentLocation.locationId}));
            }
        }
    }, [context.currentLocation?.locationId, dispatch, formDevice.deviceId]);

    React.useEffect(() => {
        if(deviceContext.reactivateSuccessful) {
            dispatch(deviceContextActions.resetReactivateStatus());
            navigate(`/devices/view/locationId=${context.currentLocation?.locationId}/brandId=${context.currentBrand?.brandId}`, {state: {editingFromDeviceId: formDevice.deviceId, editDeviceFrom: 'reactivate', editingFromDeviceIdNumber: formDevice.deviceIdNumber}});
            if (deviceContext.importResult && state?.portalUploadStatusId && state?.duplicateListUpdated) {
                dispatch(updateDuplicateList({
                    portalUploadStatusId: state?.portalUploadStatusId,
                    duplicateList: state?.duplicateListUpdated,
                }));
            }
        }
    }, [context.currentBrand?.brandId, 
                context.currentLocation?.locationId, 
                deviceContext.reactivateSuccessful, 
                dispatch, 
                formDevice.deviceId, 
                formDevice.deviceIdNumber, 
                navigate, 
                deviceContext.importResult, 
                state?.duplicateListUpdated, 
                state?.portalUploadStatusId])

    return  (
        <AppWithFooter>
            <AppBar/>
            <SideBar/>
            <main className={'o-main'}>
                <div className={'l-wrap l-container--lg'}>
                    <div className={'reactivate-root-div'}>
                        <IconButton color="primary" className={'back-button'} aria-label="back" onClick={() => {
                            if(navBackDeviceId) {
                                navigate(`/devices/edit/locationId=${context.currentLocation?.locationId}/deviceId=${navBackDeviceId}`);
                            }
                            else {
                                navigate(`/devices/view/locationId=${context.currentLocation?.locationId}/brandId=${context.currentBrand?.brandId}`);
                            }
                        }}>
                            <ArrowBack className={'back-button-arrow'}/>
                        </IconButton>
                        <div className={'reactivate-main-body-container'}>
                            {deviceContext.reactivateErrorMessage &&
                            <ServerMessage variant={'error'}>
                                <ErrorMessageSectionItem>
                                    {deviceContext.reactivateErrorMessage}
                                </ErrorMessageSectionItem>
                            </ServerMessage>
                            }
                            <div className={'reactivate-title-div'}>
                                <div className={'reactivate-title'}>
                                    {getLocalizedString('reactivateDevice.title', 'Reactivate Device')}
                                </div>
                            </div>
                            
        
                            <div className={'reactivate-restore-title'} >
                                {getLocalizedString('reactivateDevice.confirm', 'Are you sure you want to reactivate this device?')}
                            </div>
        
                            <div className={'reactivate-button-div'}>
                                <ReactivateButtonYes
                                    id="adduser-save"
                                    variant='contained'
                                    onClick={submitForm}
                                >
                                    {getLocalizedString('yes', 'Yes')}
                                </ReactivateButtonYes>
                                <ReactivateButtonNo
                                    id="adduser-cancel"
                                    variant='contained'
                                    onClick={() => {
                                        if(navBackDeviceId) {
                                            navigate(`/devices/edit/locationId=${context.currentLocation?.locationId}/deviceId=${navBackDeviceId}`);
                                        }
                                        else {
                                            navigate(`/devices/view/locationId=${context.currentLocation?.locationId}/brandId=${context.currentBrand?.brandId}`);
                                        }
                                    }}
                                >
                                    {getLocalizedString('no', 'No')}
                                </ReactivateButtonNo>
                            </div>
                            <div className={'reactivate-details-title'}>
                                {getLocalizedString('reactivateDevice.details', 'Deactivation Details')}
                            </div>
                            <div className={'reactivate-deactivation-body'}>
                                <div className={'reactivate-deactivation-reason-body'}>
                                    <div className={'reactivate-deactivation-reason-titles'}>
                                        {getLocalizedString('reactivateDevice.details.inactiveReason', 'Deactivation Reason:')}
                                    </div>
                                    <div className={'reactivate-deactivation-reason-description'}>
                                        {deviceDeactivationReasons}
                                    </div>
                                </div>
                                
                                {deviceDeactivationDetail &&
                                <div className={'reactivate-deactivation-notes-body'}>
                                    <div className={'reactivate-deactivation-notes-titles'}>
                                        {getLocalizedString('reactivateDevice.details.inactiveReasonDetail', 'Notes:')}
                                    </div>
                                    <div className={'reactivate-deactivation-notes-description'}>
                                        {deviceDeactivationDetail}
                                    </div>
                                </div>
                                }
                                
                                <div className={'reactivate-deactivation-data-body'}>
                                    <div className={'reactivate-deactivation-data-titles'}>
                                        {formDevice.deactivationUser ? getLocalizedString('reactivateDevice.details.updateUser', 'Deactivated By:') : getLocalizedString('reactivateDevice.details.inactiveReason.updateDatetime', 'Deactivated On:')}
                                    </div>
                                    <div className={'reactivate-deactivation-data-description'}>
                                        {deviceDeactivationSource}
                                    </div>
                                </div>
                            </div>
                            <div className={'reactivate-details-title'}>
                                {getLocalizedString('reactivateDevice.deviceDetails', 'Device Details')}
                            </div>
                            <div className={'reactivate-details-body'}>
                                <div className={'reactivate-details-row'}>
                                    <div className={'reactivate-details-row-title'}>
                                        {getLocalizedString('createDevice.deviceIdNumber', 'Device ID')}
                                    </div>
                                    <div className={'reactivate-details-row-data'}>
                                        {formDevice.deviceIdNumber}
                                    </div>
                                </div>
                                <div className={'reactivate-details-row'}>
                                    <div className={'reactivate-details-row-title'}>
                                        {getLocalizedString('createDevice.deviceTag', 'Device Serial Number')}
                                    </div>
                                    <div className={'reactivate-details-row-data'}>
                                        {formDevice.deviceTag}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </AppWithFooter>
    )
}

const ReactivateButtonNo = styled(Button)({
    maxHeight: '40px',
    borderRadius: '10px',
    borderWidth: '0',
    maxWidth: '100% !important',
    font: 'normal normal normal 0 "Proxima Nova"',
    fontSize: calculateResponsiveSizeWithMinMax('2', '16', '8'),
    color: '#FFFFFF',
    background: '#444444',
    letterSpacing: '0',
    width: '25%',
    padding: '2% 0 2% 0 !important',
    marginRight: '5%',
    minWidth: '0px !important',
    textTransform: 'unset',
    '&:hover': {
      backgroundColor: '#444444',
      opacity: '.5'
    }
});

const ReactivateButtonYes = styled(Button)({
    maxHeight: '40px',
    borderRadius: '10px',
    borderWidth: '0',
    maxWidth: '100% !important',
    font: 'normal normal normal 0 "Proxima Nova"',
    fontSize: calculateResponsiveSizeWithMinMax('2', '16', '8'),
    color: '#FFFFFF',
    background: '#517D9E',
    letterSpacing: '0',
    width: '25%',
    padding: '2% 0 2% 0 !important',
    marginRight: '5%',
    minWidth: '0px !important',
    textTransform: 'unset',
    '&:hover': {
      backgroundColor: '#444444',
      opacity: '.5'
    }
});
