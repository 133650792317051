import * as React from 'react';
import AppBar from "../../appbar/AppBar";
import {AppWithFooter} from "../../../App";
import {useLocation, useNavigate} from "react-router-dom";
import "../privacy/PrivacyPage.css";
import {ARCButton} from "../../ui-components/design-system/ARCButton";
import {getLocalizedString} from "../../../util/Localization";

export const TermsPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    
    const url = React.useMemo(() => {
        let path = location.pathname;
        return path.replace('terms', 'privacy');
    }, [location.pathname])
    
    const backButtonPress = React.useCallback(() => {
        navigate(-1);
    },[navigate]);

    return (
    <AppWithFooter>
         <AppBar title={<></>} right={!localStorage.getItem("token") ? <div className={'privacy-login-text'} onClick={() => navigate('/login')}>{'Log In'}</div> : undefined} logoNonInteractive={true}/>
         <main className={'o-main'}>
             <div className={'l-wrap l-container--lg'}>
                 <div className={'privacy__header'}>
                    <div className={'o-heading--l u-font-weight--700'}>
                        {'ARC Client Portal Terms & Conditions'}
                    </div>
                    <ARCButton
                         fill={'filled'}
                         variant={'primary'}
                         size={'md'}
                         id="terms-back-top"
                         onClick={backButtonPress}
                         label={getLocalizedString('back', 'Back')}
                    >
                    </ARCButton>
                </div>
                 <div className={'l-container--md o-rich-text'}>
                    <p><span className={'o-body-text'}>These terms and conditions of use (the &ldquo;</span><span className={'o-body-text'}>Terms</span><span className={'o-body-text'}>&rdquo;) apply to your access to and use of any materials, content or services provided through or accessible on this website (the &ldquo;</span><span
                           >Website</span><span className={'o-body-text'}>&rdquo;), owned and operated by ChargeitSpot LLC (&ldquo;ChargeItSpot&rdquo; or &ldquo;ARC&rdquo;) and its corporate affiliates (&ldquo;we&rdquo;, &ldquo;us&rdquo;, &ldquo;our&rdquo;). By accessing and using the Website, you accept and agree to be bound by these Terms, and all applicable laws and regulations. If you are not willing to be bound by these terms, do not use this Website.</span>
                    </p>
                    <p><span className={'o-body-text'}>PLEASE READ THESE TERMS CAREFULLY, AS THEY CONTAIN IMPORTANT INFORMATION REGARDING YOUR LEGAL RIGHTS, REMEDIES AND OBLIGATIONS, INCLUDING, BUT NOT LIMITED TO, VARIOUS LIMITATIONS, EXCLUSIONS AND INDEMNITIES. By accessing or using the Service in any way (including browsing), using any information, or submitting any content or personal information via this Website, you agree to be bound by these terms and conditions. It is your responsibility to read the terms and conditions before using this Website. If you do not expressly agree to all of the terms and conditions, then please do not access or use our Website.</span>
                    </p>
                    <p><span className={'o-body-text'}>By using the Website, you represent and warrant that (a) you are the age of legal majority in your jurisdiction; and (b) that you have the capacity to enter into and agree to these Terms. </span>
                    </p>
                    <ol>
                        <li>
                            <span className={'o-body-text'}>PROPRIETARY NOTICES</span>
                            <p><span className={'o-body-text'}>In these Terms, &ldquo;Content&rdquo; means all materials and content, including designs, text, graphics, reports, documents, software, information, formulae, patterns, data and any other work with respect to the Website and any and all integrations with our web portal.</span>
                            </p>
                            <p><span className={'o-body-text'}>Neither these Terms nor your use of the Website transfers any right, title or interest in the Website, the Content, or Intellectual Property Rights to you, and ARC retains all of its and their respective right, title and interest to the Website and Content.</span>
                            </p>
                            <ol type={'a'}>
                                <li>
                                    <span className={'o-body-text'}>Copyright Notice &ndash; The Website and all Content is owned and copyrighted by us and/or our licensors, and is licensed to you in accordance with these Terms only.</span>
                                </li>
                                <li>
                                    <span className={'o-body-text'}>Trademark Notice &ndash; The trademarks, logos, and service marks displayed on or through the Website are the property (whether registered or unregistered) of us, our licensors or other third parties. You are not permitted to use trademarks, logos and service marks for any purpose without our prior, written consent. </span>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <span className={'o-body-text'}>ACCEPTABLE USE OF THE WEBSITE</span>
                            <p><span className={'o-body-text'}>The use of the Website requires an account identifying you as the user of the Website (an &ldquo;Account&rdquo;):</span></p>
                            <ol type={'a'}>
                                <li>
                                    <p>
                                        <span className={'o-body-text'}>Account &ndash; To use the Website, you will need to register and create an account. You must provide us with accurate and complete information, and you must update your account information as needed to keep the information accurate and complete. You may not impersonate anyone else or violate any individual&rsquo;s rights. You are solely responsible for your Account and the maintenance, confidentiality, and security of your Account and all passwords related to your Account, and any and all activities that occur under your Account, including all activities of any person who gains access to your Account with or without your permission.</span>
                                    </p>
                                    <p>
                                        <span className={'o-body-text'}>You must be 18 years or older to sign up for an account and use the Website. If necessary, we may ask you for proof of age. If you are a parent or guardian and you are aware that your child has provided us with Personal Information, please contact us so that we will be able to take necessary actions.</span>
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <span className={'o-body-text'}>Acceptable Use &ndash; You agree to use the Website in accordance with applicable laws and regulations and not to engage in any activity that: Violates these Terms and Conditions; Infringes upon the intellectual property rights of others; Harasses, abuses, or harms other users; Collects or stores personal information about other users without their consent; Distributes spam or other unsolicited communications.</span>
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <span className={'o-body-text'}>Termination &ndash; We reserve the right to terminate or suspend your account and access to the Website at our sole discretion, without notice, for any reason, including, but not limited to, violation of these Terms. Upon suspension or termination of your account, you must cease all use of the Website. Your representations and warranties shall survive termination.</span>
                                    </p>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <span className={'o-body-text'}>PROHIBITED USE OF THE WEBSITE</span>
                            <p>
                                <span className={'o-body-text'}>You may not copy the Website, reverse-engineer, decompile or attempt to derive the source code or protocols for the ARC Website, use the Website in a service bureau capacity or to provide services to a third party, combine the Website with any other products or service (other than the ARC Devices), copy or emulate the features or functionality of the ARC Website, or use the ARC Website to develop or provide another product or service. </span>
                            </p>
                            <p>
                                <span className={'o-body-text'}>You agree that you shall not use any device, software, or routine intended to damage or otherwise interfere with the proper functioning of this Website or servers or networks connected to this Website, or take any other action that interferes with other parties&#39; use of this Website;</span>
                            </p>
                            <p>
                                <span className={'o-body-text'}>You agree that you shall not use any robot, spider, or other automatic or manual device or process to harvest or compile information on this Website for purposes other than for a generally available search engine;</span>
                            </p>
                            <p>
                                <span className={'o-body-text'}>You agree that you shall not use any ARC&rsquo;s names, service marks, or trademarks, or logos without our prior written consent, including as metatags, search engine keywords, or hidden text;</span>
                            </p>
                            <p>
                                <span className={'o-body-text'}>You agree that you shall not use any material or information, including images or photographs, made available through this Website in any manner that infringes any copyright, trademark, patent, trade secret, or other proprietary right of any party.</span>
                            </p>
                        </li>
                        <li>
                            <span className={'o-body-text'}>DISCLAIMERS AND LIMITS OF LIABILITY</span>
                            <ol type={'a'}>
                                <p>
                                    <span className={'o-body-text'}>Limitations &ndash; Your use of the Website depends on mobile or wireless networks, including the internet, telecommunications networks, cabling, facilities and equipment that is not in our control; accordingly (i) we cannot guarantee any minimum level regarding performance, speed, reliability, availability, use or consistency, and (ii) data, messages, information or materials sent over the internet may not be completely private, and your anonymity is not guaranteed.</span>
                                </p>
                                <p>
                                    <span className={'o-body-text'}>Warranty Disclaimers &ndash; The use of ACR&rsquo;s services and software are at your sole risk. ALTHOUGH ARC MAKES EFFORTS TO PROVIDE AN ACCURATE WEBSITE AND SERVICES, OUR SERVICES AND SOFTWARE ARE PROVIDED ON AN &quot;AS IS,&quot; &ldquo;WITH ALL FAULTS,&rdquo; AND &quot;AS AVAILABLE&quot; BASIS.&rdquo; Any information or material downloaded or otherwise obtained by way of ARC services or software will be accessed at your sole discretion and sole risk, and you are solely responsible for, and waive all claims and causes of action with respect to, any damage to your computer and/or internet access, downloading, and/or displaying, or for any loss of data that results from the download. ARC makes every reasonable effort to assure that all information published by it is correct; however, ARC disclaims any liability for errors in the services. You assume the risk of possible errors contained in the services. You agree to independently verify any information it intends to rely upon. NO ADVICE AND/OR INFORMATION, WHETHER WRITTEN OR ORAL, THAT YOU OBTAINED FROM ARC, OR BY WAY OF OR FROM OUR SERVICES OR SOFTWARE, CREATES ANY WARRANTY NOT EXPRESSLY GRANTED IN THE TERMS OF SERVICE.</span>
                                </p>
                                <p>
                                    <span className={'o-body-text'}>No Liability &ndash; Notwithstanding any other provision of these Terms, in no event will we, our subsidiaries, our affiliates, or our controlling parties, agents employees, suppliers, licensors, resellers or distributors be liable for any direct, indirect, special, incidental, consequential or punitive damages, or any other damages or losses whatsoever, including damages for loss of profits, goodwill, opportunity, earnings, use or data, arising directly or indirectly from or related to these Terms or the Website, or any Content or software in connection therewith, regardless of the cause of action and even if one or more of us have been advised of the possibility of such damages or losses, or if such damages or losses would be reasonably foreseeable.</span>
                                </p>
                            </ol>
                        </li>
                        <li>
                            <span className={'o-body-text'}>INDEMNIFICATION</span>
                            <p>
                                <span className={'o-body-text'}>You agree to indemnify and hold us, our directors, officers, employees and licensors harmless from all liabilities, actions, proceedings, claims, causes of action, demands, debts, losses, damages, charges and costs, including reasonable legal costs, any amount paid to settle any such action, or to satisfy a judgment, and expenses of any kind and character whatsoever incurred by us relating to or arising out of or in connection with your use of the Website or violation of these Terms.</span>
                            </p>
                        </li>
                        <li>
                            <span className={'o-body-text'}>PRIVACY</span>
                            <p><span className={'o-body-text'}>You acknowledge that you have read our <a href={`${url}`}>Privacy Policy</a>&nbsp;as it may be updated from time to time (the &ldquo;</span><b
                                   >Privacy Policy</b><span className={'o-body-text'}>&rdquo;), and hereby consent to the collection, use and disclosure by us and our agents of your personal information (whether previously collected or to be collected) for the purposes identified therein. Use of any personal information that you provide to us through the Site is governed by the Privacy Policy.</span>
                            </p>
                        </li>
                        <li>
                            <span className={'o-body-text'}>UPDATES TO TERMS</span>
                            <p>
                                <span className={'o-body-text'}>We reserve the right to amend these Terms and Conditions at any time without notice to you. Changes will be effective immediately and you should therefore regularly check the site to ensure that you are familiar with the most up to date terms. Your continued use of the Site following any changes to the Terms will constitute your acceptance of the revised Terms.</span>
                            </p>
                        </li>
                        <li>
                            <span className={'o-body-text'}>GENERAL PROVISIONS</span>
                            <ol type={'a'}>
                                <li>
                                    <p><span className={'o-body-text'}>Governing Law &ndash; These Terms are governed by and construed in accordance with the laws of Pennsylvania. Any dispute arising under or in connection with these Terms shall be subject to the exclusive jurisdiction of the courts of Pennsylvania. Each party waives any objections based on forum non conveniens and waives any objection to venue of any action instituted hereunder to the extent that an action is brought in the courts identified above. Each party consents to personal jurisdiction in the courts identified above.</span>
                                    </p>
                                </li>
                                <li>
                                    <p><span className={'o-body-text'}>Notifications &ndash; Subject to our Privacy Policy, we may provide you with notifications via email, in hard copy, through your Account, or through Website notices. </span>
                                    </p>
                                </li>
                                <li>
                                    <p><span className={'o-body-text'}>Errors on The Website &ndash; It is not possible to ensure that any website is free of human or technological errors. The Website may contain typographical mistakes, inaccuracies, or omissions, and some information may not be complete or current. ARC reserves the right to correct any errors, inaccuracies, or omissions, and to change or update information at any time without notice.</span>
                                    </p>
                                </li>
                                <li>
                                    <p><span className={'o-body-text'}>Waiver and Severability Of Terms &ndash; ARC&rsquo;s failure to exercise or enforce any right or provision of the Terms does not constitute a waiver. If any provision of this Terms is found by a court of competent jurisdiction to be invalid, you agree that the court should try to give effect to the parties&#39; intentions as reflected in the provision, and that the other provisions of the Terms remain in full force and effect. </span>
                                    </p>                             
                                </li>
                                <li>
                                    <p><span className={'o-body-text'}>Consent to Communication &ndash; You agree that ARC reserves the right to send electronic or paper mail to you for the purpose of informing you of changes or additions to the Website or these Terms. You further agree that from time to time ARC may contact you via electronic or paper mail for the purpose of soliciting feedback or participation in user surveys relating to the Website and associated services. Any information obtained from you will not be shared with any third parties as provided in the Terms.</span>
                                    </p>
                                </li>
                                <li>
                                    <p><span className={'o-body-text'}>Electronic Communications &ndash; When you use the Website or send emails to us, you are communicating with us electronically. you consent to receive communications from us electronically. We will communicate with you by email or by posting notices through the Website. You agree that all agreements, notices, disclosures, and other communications that we provide you electronically satisfy any legal requirement that such communications be in writing.</span>
                                    </p>
                                </li>
                                <li>
                                    <p><span className={'o-body-text'}>English Language &ndash; The parties have requested and agree that this agreement and all documents relating thereto be drawn up in English / </span><span
                                    >Les parties ont demand&eacute; que cette convention ainsi que tous les documents qui s&rsquo;y rattachent soient r&eacute;dig&eacute;s en anglais.</span></p>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <span className={'o-body-text'}>CONTACT INFORMATION</span>
                            <p><span className={'o-body-text'}>If you have any questions or concerns about these Terms and Conditions, please contact us at </span><span
                            ><a href="mailto:privacy@chargeitspot.com">privacy@chargeitspot.com</a></span><span className={'o-body-text'}>.</span></p>
                        </li>
                    </ol>
                </div>
                <div className={'privacy-back-button-div'}>
                    <ARCButton
                        fill={'filled'}
                        variant={'primary'}
                        size={'md'}
                        id="terms-back-bottom"
                        onClick={backButtonPress}
                        label={getLocalizedString('back', 'Back')}
                    >
                    </ARCButton>
                </div>
             </div>
         </main>
    </AppWithFooter>
    )
}
