import * as React from 'react';
import {ARCIcon} from "./ARCIcon";

type ARCCheckboxProps = {
    id: string,
    size: 'sm' | 'md' | 'lg',
    label?: string,
    required?: boolean,
    disabled?: boolean;
    checked: boolean;
    indeterminate?: boolean;
    onChange: () => void;
    validate?: () => string | undefined;
    className?: string;
    style?: React.CSSProperties;
}

export const ARCCheckbox : React.FC<ARCCheckboxProps> = (props) => {
    const [inputError, setInputError] = React.useState<string | undefined>(undefined);

    const currentIcon = React.useMemo(() => {
        return props.indeterminate ? 'minus' : 'checkmark';
    }, [props.indeterminate]);

    return (
        <div className={'o-checkbox__container'}>
            <label className={`o-checkbox o-checkbox--${props.size} ${props.checked ? 'checked' : ''} ${props.disabled ? 'o-checkbox__disabled' : ''}`}>
                <input
                    type="checkbox"
                    checked={props.checked}
                    onChange={() => {
                        props.onChange();

                        if(props.validate) {
                            setInputError(props.validate());
                        }
                    }}
                    style={{cursor: 'pointer'}}
                />

                <ARCIcon icon={currentIcon} size={'sm'} color={'white'} className="checkmark"/>
            </label>

            {props.label &&
            <div className={'o-checkbox__label__container'}>
                <label className={`o-checkbox__label--${props.size}`}>
                    {props.label}

                    {props.required &&
                    <div className={'required-star'}>
                        {'*'}
                    </div>
                    }
                </label>

                {inputError &&
                <div className={'o-checkbox__error'}>
                    {inputError}
                </div>
                }
            </div>
            }
        </div>
    )
}