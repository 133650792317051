import { Search } from '@material-ui/icons';
import * as React from 'react';
import { ChangeEvent } from 'react';
import {getLocalizedString} from "../../../util/Localization";

type ARCTextFilterInputProps = {
    id: string,
    label?: string,
    placeholder?: string,
    disabled?: boolean;
    value: string | undefined,
    onChange: (event: ChangeEvent<HTMLInputElement>) => void,
    onClear: () => void,
    onSearch: () => void,
    className?: string,
    style?: React.CSSProperties
}

export const ARCTextFilterInput : React.FC<ARCTextFilterInputProps> = (props) => {

    return (
        <div className={`o-textinput--filter__container ${props.className ? props.className : ''}`} style={props.style}>
            <div className={'u-display--flex u-align-items--center u-justify-content--space-between'}>
                <label className={`o-textinput--filter__label`}>
                    {props.label}
                </label>

                {props.value &&
                <div className={'o-textinput--filter__clear'} onClick={props.onClear}>
                    {getLocalizedString('clear', 'Clear')}
                </div>
                }
            </div>

            <div style={{position: 'relative'}}>
                <input type={'text'}
                       disabled={props.disabled}
                       placeholder={props.placeholder ?? ''}
                       className={`o-textinput--filter`}
                       value={props.value}
                       onChange={(event) => {
                           props.onChange(event);
                       }}
                       onKeyDown={(event) => {
                           if (event.key === 'Enter') {
                               props.onSearch();
                           }
                       }}
                />
                <Search className={'o-textinput--filter__icon'} onClick={() => props.onSearch()}/>
            </div>


        </div>
    )
}