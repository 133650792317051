import {ARCIcon, ARCIcons, ARCIconSizes} from "./ARCIcon";
import * as React from "react";

export type ARCTextLinkSizeType = 'sm' | 'md';
export type ARCTextLinkVariantType = 'primary' | 'secondary';

type ARCTextLinkType = {
    size: ARCTextLinkSizeType,
    variant: ARCTextLinkVariantType,
    label: string,
    disabled? : boolean,
    icon?: ARCIcons,
    iconSize?: ARCIconSizes
    onClick: () => void,
    id?: string
}

export const ARCTextLink : React.FC<ARCTextLinkType> = (props) => {

    const textSizeClass = React.useMemo(() =>{
        if (props.size === 'sm') {
            return 'o-textlink--s';
        } else {
            return 'o-textlink--m';
        }
    }, [props.size]);

    return (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid 
        <a className={`o-textlink ${textSizeClass} o-textlink--${props.variant} ${props.disabled ? 'o-textlink--disabled' : ''}` }
           href=""
           onClick={() => {
                    props.onClick();
                    return false;
                }
            }>
            {props.icon && <ARCIcon icon={props.icon} size={props.iconSize ?? props.size}/>}
            {props.label}
        </a>
    )
}

export type ARCTextLinkComponentType = React.FC<ARCTextLinkType>;
