import {Dispatch, SetStateAction} from "react";
import {isAlphanumeric, isNumeric} from "./TextUtils";
import {MAX_DEVICE_TAG_LENGTH} from "../constants/DeviceConstants";
import {BrandDto, DeviceNumberComposition} from "../dtos/Brand";
import {LocationDto} from "../dtos/Location";
import {getDeviceIdNumberValidation} from "../dtos/Device";
import {getLocalizedString} from "./Localization";

export type DeviceValidationDto = {
    validDevices: string[],
    deviceTagErrors: string[],
    deviceIdNumberErrors: string[],
    deviceTypeErrors: string[],
    invalidDevicesCount: number,
    duplicateDevices: string[]
}
export function validateDeviceUpload(file: File, brand: BrandDto, location: Omit<LocationDto, "brand">, setProgress: Dispatch<SetStateAction<number>>, templateHeader: string[]): Promise<DeviceValidationDto> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = async (event: ProgressEvent<FileReader>) => {
            if (!event.target) {
                reject(new Error('File reader target not found'));
                return;
            }
            const csvData: string = event.target.result as string;
            const rows: string[] = csvData.replace(/\r/g, '').split('\n');
            if (rows.length <= 1) {
                reject(new Error('Empty File'))
                return;
            } else {
                const header = rows[0].split(',');
                if (header.length !== templateHeader.length) {
                    reject(new Error('Header does not match template'))
                    return;
                }
                for (let i = 0; i < header.length; i++) {
                    if (header[i] !== templateHeader[i]) {
                        reject(new Error('Header does not match template'))
                        return;
                    }
                }
            }
            const totalRows = rows.length;
            let processedRows = 0;
            let deviceValidationDto: DeviceValidationDto = {
                validDevices: [],
                deviceTagErrors: [],
                deviceIdNumberErrors: [],
                deviceTypeErrors: [],
                invalidDevicesCount: 0,
                duplicateDevices: []
            }
            
            const existingDevices: {deviceId: string; deviceTag: string;}[] = [];
            
            setProgress(0);
            if (totalRows > 0) {
                const updateStateInterval = Math.ceil(totalRows/100);
                const asyncProcessRows = async () => {
                    const delay = () => new Promise((resolve) => setTimeout(resolve, 0));
                    for (let i = 0; i < totalRows; i++) {
                        const rowIndex = i;
                        const row = rows[i];
                        const isEmptyRow = row.replace(/,| /g, '') === '';
                        if (rowIndex >= 1 && !isEmptyRow) {
                            const columns: string[] = row.split(',');
                            processedRows++;
                            const progress = Math.ceil((processedRows / totalRows) * 100);
                            // console.log(`Row ${rowIndex + 1}, Column ${columnIndex + 1}: ${column}`);
                            const deviceTag = columns[0];
                            const deviceIdNumber = columns[1];
                            const deviceType = columns[2];
                            let validRow = true;
                            let deviceKey = getLocalizedString('import.review.row', 'Row: ') + (rowIndex + 1).toString();
                            if (!(deviceTag && deviceTag.length > 0)) {
                                deviceValidationDto.deviceTagErrors.push(deviceKey);
                                validRow = false;
                            } else {
                                if (!isAlphanumeric(deviceTag)) {
                                    deviceValidationDto.deviceTagErrors.push(deviceKey);
                                    validRow = false;
                                } else if (deviceTag.length > MAX_DEVICE_TAG_LENGTH) {
                                    deviceValidationDto.deviceTagErrors.push(deviceKey);
                                    validRow = false;
                                }
                            }

                            if (deviceIdNumber && deviceIdNumber.length > 0) {
                                if (brand.deviceNumberPrefix && !deviceIdNumber.startsWith(brand.deviceNumberPrefix)) {
                                    deviceValidationDto.deviceIdNumberErrors.push(deviceKey);
                                    validRow = false;
                                }
                                
                                let brandProvidedError = undefined;
                                let cisProvidedError = undefined;
                                if (brand.deviceNumberLengthMin) {
                                    const brandLength = deviceIdNumber.length;
                                    if (brand.deviceNumberLengthMax) {
                                        if (brandLength < brand.deviceNumberLengthMin || brandLength > brand.deviceNumberLengthMax) {
                                            if (brand.deviceNumberLengthMin === brand.deviceNumberLengthMax) {
                                                brandProvidedError = 'true';
                                            } else {
                                                brandProvidedError = 'true';
                                            }
                                        }
                                    } else if (brandLength < brand.deviceNumberLengthMin) {
                                        brandProvidedError = 'true';
                                    }
                                } else if (brand.deviceNumberLength && deviceIdNumber.length !== brand.deviceNumberLength) {
                                    cisProvidedError = 'true';
                                }
                                const deviceIdNumberError = getDeviceIdNumberValidation(brand.deviceNumberLengthMin, brand.deviceNumberLength, location.serialIsDevice, brandProvidedError, cisProvidedError);
                                if (deviceIdNumberError && deviceIdNumberError.length > 0) {
                                    deviceValidationDto.deviceIdNumberErrors.push(deviceKey);
                                    validRow = false;
                                }

                                if (brand.deviceNumberComposition != null) {
                                    if (brand.deviceNumberComposition === DeviceNumberComposition.NUMERIC && !isNumeric(deviceIdNumber)) {
                                        deviceValidationDto.deviceIdNumberErrors.push(deviceKey);
                                        validRow = false;
                                    } else if (brand.deviceNumberComposition === DeviceNumberComposition.NUMERIC && !isAlphanumeric(deviceIdNumber)) {
                                        deviceValidationDto.deviceIdNumberErrors.push(deviceKey);
                                        validRow = false;
                                    }
                                }
                            }

                            if (location.deviceTypes && location.deviceTypes.length > 0) {
                                if (deviceType && deviceType.length > 0) {
                                    let foundType = false;
                                    location.deviceTypes.forEach((locationDeviceType) => {
                                        if (locationDeviceType.deviceTypeName === deviceType.trim()) {
                                            foundType = true;
                                        }
                                    })
                                    if (!foundType) {
                                        deviceValidationDto.deviceTypeErrors.push(deviceKey);
                                        validRow = false;
                                    }
                                } else {
                                    deviceValidationDto.deviceTypeErrors.push(deviceKey);
                                    validRow = false;
                                }
                            }
                            
                            if (existingDevices.length > 0) {
                                for (let i = 0; i < existingDevices.length; i++) {
                                    if (((!!existingDevices[i].deviceId && !!deviceIdNumber) && existingDevices[i].deviceId === deviceIdNumber) || existingDevices[i].deviceTag === deviceTag) {
                                        deviceValidationDto.duplicateDevices.push(deviceKey)
                                        validRow = false;
                                    }
                                }
                            }
                                
                            if (validRow) {
                                existingDevices.push({deviceId: deviceIdNumber, deviceTag: deviceTag})
                                deviceValidationDto.validDevices.push(deviceTag);
                            } else {
                                deviceValidationDto.invalidDevicesCount++;
                            }

                            if ((processedRows % updateStateInterval) === 0) {
                                setProgress(progress);
                                await delay();
                            }
                        }
                    }
                    setProgress(100);
                }
                await asyncProcessRows()
            }
            resolve(deviceValidationDto);
        };

        reader.onerror = (event: ProgressEvent<FileReader>) => {
            reject(event.target?.error || new Error('Unknown error occurred'));
        };

        reader.readAsText(file);
    });
}



