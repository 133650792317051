import * as React from 'react';
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {IconButton} from "@material-ui/core";
import {appContextSelector, sortedDeviceIssuesSelector} from "../../app-context/appContextSlice";
import {
    deactivateDevice,
    deviceContextActions,
    deviceContextSelector,
    getDeviceForEdit,
    getDeviceStats, updateDuplicateList
} from "../deviceSlice";
import {
    DEV_STATUS_INACTIVE,
    DeviceDto,
    DeviceStatDto,
    INACTIVE_REASON_NONFUNCTIONAL,
    INACTIVE_REASONS
} from "../../../dtos/Device";
import AppBar from "../../appbar/AppBar";
import {SideBar} from "../../sidebar/SideBar";
import {BLACK, RED} from "../../../themes/colors";
import {calculateResponsiveSizeWithMinMax} from "../../../util/TextUtils";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {makeStyles, styled} from "@material-ui/core/styles";
import {AppWithFooter} from "../../../App";
import {
    BoldText,
    BoldTextSm,
    DeviceStatsDiv,
    ErrorMessageSectionItem,
    SmText,
    XSmallSpacer
} from "../../ui-components/StyledComponents";
import {selectLocationThunk} from "../../app-context/thunks/SelectLocationThunk";
import {LocationState} from "../../../@types/types";
import {ServerMessage} from "../../ui-components/ServerMessage";
import {ArrowBack} from "@material-ui/icons";
import {DeactivateDeviceNotes} from "./DeactivateDeviceNotes";
import {getLocalizedString} from "../../../util/Localization";
import {ARCButton} from "../../ui-components/design-system/ARCButton";
import {ARCCheckbox} from "../../ui-components/design-system/ARCCheckbox";
import {ARCRadioInput} from "../../ui-components/design-system/ARCRadioInput";


const useAutoSelectLocationByUrl = () => {
    const context = useAppSelector(appContextSelector);
    const dispatch = useAppDispatch();
    const {locationId, deviceId} = useParams();

    React.useEffect(() => {
        if(locationId && context.currentLocation?.locationId !== parseInt(locationId)) {
            dispatch(selectLocationThunk(parseInt(locationId)));

            if(deviceId) {
                dispatch(getDeviceForEdit({locationId: parseInt(locationId), deviceId: parseInt(deviceId)}));
            }
        }
        else if(deviceId && context.currentLocation?.locationId) {
            dispatch(getDeviceForEdit({locationId: context.currentLocation.locationId, deviceId: parseInt(deviceId)}));
        }

    }, [context.currentLocation?.locationId, dispatch, deviceId, locationId]);
}

export const DeactivateDevice = () => {
    useAutoSelectLocationByUrl();
    const styles = useStyles();

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const context = useAppSelector(appContextSelector);
    const deviceContext = useAppSelector(deviceContextSelector);
    const deviceStats: DeviceStatDto | undefined = deviceContext.selectedDeviceStats;
    const brandDeviceIssues = useAppSelector(sortedDeviceIssuesSelector);

    const [formDevice, setFormDevice] = React.useState<DeviceDto>({
        deviceId: undefined,
        deviceTag: '',
        deviceIdNumber: '',
        status: DEV_STATUS_INACTIVE,
        locationId: undefined,
        inactiveReason: '',
        inactiveReasonDetail: '',
        deviceType: undefined
    });

    const [navBackDeviceId, setNavBackDeviceId] = React.useState<number | undefined>(undefined);
    const [showNotes, setShowNotes] = React.useState<boolean>(false);

    const {state} = useLocation() as LocationState;
    React.useEffect(() => {
        if(state) {
            const { editingFromDeviceId } = state; // Read values passed on state
            if(editingFromDeviceId) {
                setNavBackDeviceId(editingFromDeviceId);
            }
        }
    }, [state]);

    React.useEffect(() => {
        return () => {dispatch(deviceContextActions.resetDeactivateStatus())}
    }, [dispatch]);

    const stateDevice = deviceContext.selectedDevice;

    React.useEffect(() => {
        if(stateDevice) {
            setFormDevice(stateDevice);

            if(stateDevice.deviceId && context.currentLocation?.locationId) {
                dispatch(getDeviceStats({deviceId: stateDevice.deviceId, locationId: context.currentLocation.locationId}));
            }
        }
    }, [context.currentLocation?.locationId, dispatch, stateDevice]);

    const submitForm = React.useCallback(() => {
        if(context.currentLocation?.locationId) {
            dispatch(deactivateDevice({deviceDto: formDevice, locationId: context.currentLocation?.locationId}));
        }
    }, [context.currentLocation?.locationId, dispatch, formDevice]);
    
    const nextDisabled = React.useMemo(() => {
        if (!formDevice.inactiveReason || formDevice.inactiveReason?.length === 0) {
            return true;
        } else if (formDevice.inactiveReason === INACTIVE_REASON_NONFUNCTIONAL.value) {
            if (!formDevice.deviceIssues || formDevice.deviceIssues?.length === 0) {
                if (brandDeviceIssues.length > 0) {
                    return true;
                }
            }
        }
        return false;
    },[formDevice.inactiveReason, formDevice.deviceIssues, brandDeviceIssues])

    React.useEffect(() => {
        if(deviceContext.deactivateSuccessful) {
            navigate(`/devices/view/locationId=${context.currentLocation?.locationId}/brandId=${context.currentBrand?.brandId}`, 
            {state: {editingFromDeviceId: formDevice.deviceId, editDeviceFrom: 'deactivate', editingFromDeviceIdNumber: formDevice.deviceIdNumber, editingFromDeviceTag: formDevice.deviceTag}});
            dispatch(deviceContextActions.resetDeactivateStatus());
            if (deviceContext.importResult && state?.portalUploadStatusId && state?.duplicateListUpdated) {
                dispatch(updateDuplicateList({
                    portalUploadStatusId: state?.portalUploadStatusId,
                    duplicateList: state?.duplicateListUpdated,
                }));
            }
        }
    }, [context.currentBrand?.brandId, 
              context.currentLocation?.locationId, 
              deviceContext.deactivateSuccessful, 
              dispatch, 
              formDevice.deviceId, 
              formDevice.deviceIdNumber, 
              formDevice.deviceTag, 
              navigate,
              deviceContext.importResult, 
              state?.duplicateListUpdated, 
              state?.portalUploadStatusId]);

    return  (
        <AppWithFooter>
            <AppBar/>
            <SideBar/>
            <main className={'o-main'}>
                <div className={'l-wrap l-container--lg'}>
                    {deviceContext.deactivateErrorMessage &&
                        <ServerMessage variant={'error'}>
                            <ErrorMessageSectionItem>
                                {deviceContext.deactivateErrorMessage}
                            </ErrorMessageSectionItem>
                        </ServerMessage>
                    }
                    {showNotes ?
                        <DeactivateDeviceNotes
                            formDevice={formDevice}
                            setFormDevice={setFormDevice}
                            setShowNotes={setShowNotes}
                            submitForm={submitForm}
                            styles={styles}
                        />
                    :
                        <div className={styles.form_root}>
                            <IconButton color="primary" className={'back-button'} aria-label="back" onClick={() => {
                                if (navBackDeviceId) {
                                    navigate(`/devices/edit/locationId=${context.currentLocation?.locationId}/deviceId=${navBackDeviceId}`);
                                } else {
                                    navigate(`/devices/view/locationId=${context.currentLocation?.locationId}/brandId=${context.currentBrand?.brandId}`);
                                }
                            }}>
                                <ArrowBack className={'back-button-arrow'}/>
                            </IconButton>

                            <div style={{width: '80%'}}>
                                <div className={styles.form_title_div}>
                                    <div className={styles.form_title_text}>
                                        {getLocalizedString('deactivateDevice.title', 'Deactivate Device')} {formDevice.deviceTag}
                                    </div>
                                </div>


                                {deviceStats &&
                                    <DeviceStatsDiv>
                                        <BoldText variant='h6'>{getLocalizedString('deactivateDevice.stats', 'Device Stats')}</BoldText>

                                        <XSmallSpacer/>

                                        <div style={{display: 'flex', alignItems: 'baseline'}}>
                                            <BoldTextSm style={{marginRight: '1vw'}}>{getLocalizedString('deactivateDevice.stats.lastPickup', 'Last Pickup:')}</BoldTextSm>
                                            <SmText>
                                                {deviceStats.lastPickup}
                                            </SmText>
                                        </div>

                                        <div style={{display: 'flex', alignItems: 'baseline'}}>
                                            <BoldTextSm style={{marginRight: '1vw'}}>{getLocalizedString('deactivateDevice.stats.lastReturn', 'Last Return:')}</BoldTextSm>
                                            <SmText>
                                                {deviceStats.lastReturn}
                                            </SmText>
                                        </div>

                                        <XSmallSpacer/>

                                        <BoldTextSm>{getLocalizedString('deactivateDevice.stats.recentIssues', 'Most Recent Reported Issues:')}</BoldTextSm>
                                        {
                                            deviceStats.recentIssues?.map(issue => {
                                                return <SmText>{issue}</SmText>
                                            })
                                        }

                                    </DeviceStatsDiv>
                                }


                                <BoldText variant='h6' style={{display: 'flex'}}>
                                    <span>
                                        {getLocalizedString('deactivateDevice.inactiveReason', 'Select Deactivation Reason:')}
                                        <RedBox>*</RedBox>
                                    </span>
                                </BoldText>

                                <div className={'u-display--flex u-display--flex-column u-space-sm u-gap-sm'}>
                                        {
                                            Object.values(INACTIVE_REASONS).map((option, index) => {
                                                return (
                                                    <div>
                                                        {(INACTIVE_REASON_NONFUNCTIONAL.value === option.value && (brandDeviceIssues !== undefined && brandDeviceIssues?.length) > 0) ?
                                                            <div>
                                                                <ARCRadioInput
                                                                    id={'inactive-reason-radio-' + index}
                                                                    size={'md'}
                                                                    style={{marginRight: '5px'}}
                                                                    onChange={() => setFormDevice({...formDevice, inactiveReason: option.value})}
                                                                    on={formDevice.inactiveReason === option.value}
                                                                    label={getLocalizedString(option.labelKey, option.value)}
                                                                />
                                                                <div className={'u-display--flex u-display--flex-column u-space-sm u-gap-sm'}>
                                                                    {brandDeviceIssues?.map((issue, idx) => {
                                                                        return (
                                                                            <ARCCheckbox
                                                                                id={'add-device-issue-checkbox-' + idx}
                                                                                size={'md'}
                                                                                checked={!!formDevice.deviceIssues?.some(deviceIssue => deviceIssue.brandDeviceIssueId === issue.brandDeviceIssueId)}
                                                                                label={issue.deviceIssueType}
                                                                                onChange={() => {
                                                                                    if (!formDevice.deviceIssues?.find(deviceIssue => deviceIssue.brandDeviceIssueId === issue.brandDeviceIssueId)) {
                                                                                        if (formDevice.deviceIssues) {
                                                                                            setFormDevice({
                                                                                                ...formDevice,
                                                                                                inactiveReason: INACTIVE_REASON_NONFUNCTIONAL.value,
                                                                                                deviceIssues: [...formDevice.deviceIssues, issue]
                                                                                            });
                                                                                        } else {
                                                                                            setFormDevice({
                                                                                                ...formDevice,
                                                                                                inactiveReason: INACTIVE_REASON_NONFUNCTIONAL.value,
                                                                                                deviceIssues: [issue]
                                                                                            });
                                                                                        }
                                                                                    } else {
                                                                                        setFormDevice({
                                                                                            ...formDevice,
                                                                                            inactiveReason: INACTIVE_REASON_NONFUNCTIONAL.value,
                                                                                            deviceIssues: formDevice.deviceIssues?.filter(deviceIssue => deviceIssue.brandDeviceIssueId !== issue.brandDeviceIssueId)
                                                                                        })
                                                                                    }
                                                                                }
                                                                                }
                                                                            />
                                                                        )
                                                                    })}
                                                                </div>
                                                            </div>

                                                            :

                                                            <ARCRadioInput
                                                                id={'inactive-reason-radio-' + index}
                                                                size={'md'}
                                                                style={{marginRight: '5px'}}
                                                                onChange={() => setFormDevice({...formDevice, inactiveReason: option.value, deviceIssues: []})}
                                                                on={formDevice.inactiveReason === option.value}
                                                                label={getLocalizedString(option.labelKey, option.value)}
                                                            />
                                                        }

                                                    </div>
                                                )
                                            })
                                        }
                                </div>

                                <div className={styles.buttonDiv}>
                                    <ARCButton
                                        fill={'filled'}
                                        variant={'primary'}
                                        size={'md'}
                                        id="adduser-save"
                                        disabled={nextDisabled}
                                        onClick={() => setShowNotes(true)}
                                        label={getLocalizedString('deactivateDevice.submit', 'Next')}
                                    >
                                    </ARCButton>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </main>
        </AppWithFooter>
    )
}

const useStyles = makeStyles((theme) => ({
    form: {
        width: '100%'
    },
    form_root: {
        display: 'flex',
        marginTop: '2%',
        flexGrow: 1,
        maxWidth: '70vw'
    },
    form_title_div: {
        display: 'flex',
        flexWrap: 'wrap',
        alignContent: 'center',
        justifyContent: 'flex-start',
        marginBottom: '5%',
    },
    form_title_text: {
        display: 'flex',
        flexWrap: 'wrap',
        alignContent: 'center',
        justifyContent: 'flex-start',
        textAlign: 'left',
        fontSize: 'clamp(12px, (4vh + 4vw)/2, 37px)',
        fontWeight: 'bold',
        color: theme.palette.primary.main,
        opacity: '1',
    },
    input_root: {
        display: 'flex',
        flexDirection: 'column',
        margin: 0,
        padding: 0,
    },
    input_label: {
        position: "initial",
        color: BLACK
    },
    input_text: {
        padding: '1vh 0 0 1vh',
        marginTop: theme.spacing(.5)
    },
    inactive_reason: {
        display: 'flex',
        width: '100%'
    },
    reason_detail: {
        display: 'flex',
        alignItems: 'center'
    },
    reason_detail_label: {
      fontSize: calculateResponsiveSizeWithMinMax('2', '20', '8')
    },
    reason_detail_text: {
        border: '2px solid',
        borderColor: theme.palette.primary.main,
        borderRadius: '11px',
        margin: 0,
        marginBottom: '20px'
    },
    reason_detail_text_disabled: {
        border: '2px solid',
        borderColor: theme.palette.primary.main,
        borderRadius: '11px',
        paddingLeft: '10px'
    },
    reason_detail_required_text: {
        fontSize: 'clamp(6px, (1.5vh + 1.5vw)/2, 20px)',
        opacity: '.5'
    },
    topActionsDiv: {
        display: 'flex',
        width: '50%',
        justifyContent: 'space-evenly'
    },
    buttonDiv: {
        display: 'flex',
        marginTop: '5vh',
        maxWidth: '500px',
        justifyContent: 'flex-start',
        [`${theme.breakpoints.down('md')}`]: {
            maxWidth: 'unset',
        }
    }
}));

const RedBox = styled("span")({
    color: RED
});
