import * as React from 'react';
import {AppWithFooter} from "../../../App";
import {FormTitle } from '../../ui-components/StyledComponents';
import SearchSelect from "../../ui-components/SearchSelect";
import {KioskDataContainer} from "../components/KioskDataContainer";
import {HELP_PHONE} from "../../../constants/StringConstants";
import {
    emergencyPortalContextSelector,
    emergencyPortalGetKiosk,
    emergencyPortalGetKiosks
} from "../emergencyPortalSlice";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {isNumeric} from "../../../util/TextUtils";
import TabMenu from "../../ui-components/TabMenu";
import LOCKER_TAB_48 from "../../../assets/img/locker-images/Locker_Tab_48.png"; 
import LOCKER_TAB_8 from "../../../assets/img/locker-images/Locker_Tab_8.png";
import LARGE_LOCKER_INSTRUCTIONS_1_2x from "../../../assets/img/locker-images/Large_Locker_Instructions_1_2x.png";
import LARGE_LOCKER_INSTRUCTIONS_2_2x from "../../../assets/img/locker-images/Large_Locker_Instructions_2_2x.png";
import LARGE_LOCKER_INSTRUCTIONS_3_2x from "../../../assets/img/locker-images/Large_Locker_Instructions_3_2x.png";
import LARGE_LOCKER_INSTRUCTIONS_4_2x from "../../../assets/img/locker-images/Large_Locker_Instructions_4_2x.png";
import LARGE_LOCKER_INSTRUCTIONS_5_2x from "../../../assets/img/locker-images/Large_Locker_Instructions_5_2x.png";
import LARGE_LOCKER_INSTRUCTIONS_1 from "../../../assets/img/locker-images/Large_Locker_Instructions_1.png";
import LARGE_LOCKER_INSTRUCTIONS_2 from "../../../assets/img/locker-images/Large_Locker_Instructions_2.png";
import LARGE_LOCKER_INSTRUCTIONS_3 from "../../../assets/img/locker-images/Large_Locker_Instructions_3.png";
import LARGE_LOCKER_INSTRUCTIONS_4 from "../../../assets/img/locker-images/Large_Locker_Instructions_4.png";
import LARGE_LOCKER_INSTRUCTIONS_5 from "../../../assets/img/locker-images/Large_Locker_Instructions_5.png";
import SMALL_LOCKER_INSTRUCTIONS_1_2x from "../../../assets/img/locker-images/Small_Locker_Instructions_1_2x.png";
import SMALL_LOCKER_INSTRUCTIONS_2_2x from "../../../assets/img/locker-images/Small_Locker_Instructions_2_2x.png";
import SMALL_LOCKER_INSTRUCTIONS_3_2x from "../../../assets/img/locker-images/Small_Locker_Instructions_3_2x.png";
import SMALL_LOCKER_INSTRUCTIONS_4_2x from "../../../assets/img/locker-images/Small_Locker_Instructions_4_2x.png";
import SMALL_LOCKER_INSTRUCTIONS_1 from "../../../assets/img/locker-images/Small_Locker_Instructions_1.png";
import SMALL_LOCKER_INSTRUCTIONS_2 from "../../../assets/img/locker-images/Small_Locker_Instructions_2.png";
import SMALL_LOCKER_INSTRUCTIONS_3 from "../../../assets/img/locker-images/Small_Locker_Instructions_3.png";
import SMALL_LOCKER_INSTRUCTIONS_4 from "../../../assets/img/locker-images/Small_Locker_Instructions_4.png";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {useTheme} from "@material-ui/core";
import {appContextSelector} from "../../app-context/appContextSlice";
import {SideBar} from "../../sidebar/SideBar";
import AppBar from "../../appbar/AppBar";
import {getLocalizedString} from "../../../util/Localization";


export const EmergencyPortalViewKeypadCodes = () => {
    const dispatch = useAppDispatch();
    const appContext = useAppSelector(appContextSelector);
    const context = useAppSelector(emergencyPortalContextSelector)
    const kioskList = context.kiosks;
    const selectedKiosk = context.selectedKiosk;
    const theme = useTheme();
    const isSmOrBelow = useMediaQuery(theme.breakpoints.down('xs'));

    React.useEffect(() => {
        dispatch(emergencyPortalGetKiosks({brandId: appContext.currentBrand?.brandId}));
    }, [appContext.currentBrand?.brandId, dispatch]);

    const kioskOptions = React.useMemo(() => {
        const sortedList = kioskList.slice().sort((k1, k2) => {
            return k1.serialNumber.localeCompare(k2.serialNumber);
        })
        
        return sortedList.map(kiosk => {
            return {
                name: kiosk.serialNumber,
                value: `${kiosk.kioskId}`
            }
        })
    }, [kioskList]);

    const onKioskSelect = React.useCallback((kioskId: string) => {
        if (kioskId && isNumeric(kioskId)) {
            const kioskIdNum = parseInt(kioskId);  
            dispatch(emergencyPortalGetKiosk({kioskId: kioskIdNum}))
        }
    }, [dispatch]);


    return (
        <AppWithFooter>
            <AppBar/>
            <SideBar/>
            <main className={'o-main'}>
                <div className={'l-wrap l-container--lg'}>
                    <div style={{width: '100%'}}>
                        <FormTitle variant='h5' className={'o-heading--xl'} style={{paddingBottom: '15px', width: '100%'}}>
                            {getLocalizedString('emergencyPortal.titleFull', 'Emergency Kiosk Access')}
                        </FormTitle>
                        <FormTitle style={{borderBottom: '2px solid black', paddingBottom: '5px'}}>
                            {getLocalizedString('emergencyPortal.combo', 'Combo Lock Code')}
                        </FormTitle>
    
                        <p className={'emergency-help-text'}>
                            {getLocalizedString('emergencyPortal.manual', 'If the kiosk is malfunctioning, manually release devices using the kiosk\'s combo code.')}
                        </p>
    
                        <div style={{display: 'flex', flexWrap: 'wrap'}}>
                            <div style={{margin: '0 3vh 2vh 0'}}>
                                <b className={'combo-section-label'}>
                                    {getLocalizedString('emergencyPortal.serial', 'Kiosk Serial Number')}
                                </b>
    
                                <SearchSelect options={kioskOptions}
                                              disabledOnSingleOption={true}
                                              placeHolder={getLocalizedString('search.search', 'Search...')}
                                              onSelect={onKioskSelect}>
    
                                </SearchSelect>
                            </div>
    
                            <div style={{marginBottom: '3vh'}}>
                                <b className={'combo-section-label'}>
                                    {getLocalizedString('emergencyPortal.details', 'Kiosk Details')}
                                </b>
    
                                <div className={'kiosk-container'}>
                                    <KioskDataContainer kiosk={selectedKiosk}/>
                                </div>
    
                                {selectedKiosk ?
                                selectedKiosk.keypadCode ?
                                    undefined
                                    :
                                    <span className={'kiosk-error-message'}>
                                        {getLocalizedString('emergencyPortal.call', 'Call') + ' ' + getLocalizedString('support.phone', HELP_PHONE)}
                                    </span>
                                    :
                                <span className={'kiosk-error-message'}>
                                    {getLocalizedString('emergencyPortal.selectKiosk', 'Select a Kiosk Serial Number')}
                                </span>
                                }
                            </div>
                        </div>
    
                        <FormTitle style={{borderBottom: '2px solid black', marginBottom: '15px', paddingBottom: '5px'}}>
                            {getLocalizedString('emergencyPortal.manualRelease', 'Manually Releasing Devices')}
                        </FormTitle>
    
                        <TabMenu tabs={
                            [
                                {
                                    label: getLocalizedString('emergencyPortal.24&48', '48 & 24 Locker Kiosks'),
                                    labelContent: 
                                        <div className={'tab-image-div'} style={isSmOrBelow ? {width: '30px', height: '25px', marginLeft: '10px'} : {}}>
                                            <img className={'tab-image'} alt={'Tab for instructions on 48 locker kiosks'} src={LOCKER_TAB_48}/>
                                        </div>
                                    ,
                                    content: 
                                        <div>
                                            {isSmOrBelow ?
                                                <div className={'u-display--flex u-flex-wrap'}>
                                                    <img src={LARGE_LOCKER_INSTRUCTIONS_1} alt={'48 Locker Instruction Step #1'} className={'tab-content-image'}/>
                                                    <img src={LARGE_LOCKER_INSTRUCTIONS_2} alt={'48 Locker Instruction Step #2'} className={'tab-content-image'}/>
                                                    <img src={LARGE_LOCKER_INSTRUCTIONS_3} alt={'48 Locker Instruction Step #3'} className={'tab-content-image'}/>
                                                    <img src={LARGE_LOCKER_INSTRUCTIONS_4} alt={'48 Locker Instruction Step #4'} className={'tab-content-image'}/>
                                                    <img src={LARGE_LOCKER_INSTRUCTIONS_5} alt={'48 Locker Instruction Step #5'} className={'tab-content-image'}/>
                                                </div>
                                                :
                                                <div className={'u-display--flex u-flex-wrap'}>
                                                    <img src={LARGE_LOCKER_INSTRUCTIONS_1_2x} alt={'48 Locker Instruction Step #1'} className={'tab-content-image'}/>
                                                    <img src={LARGE_LOCKER_INSTRUCTIONS_2_2x} alt={'48 Locker Instruction Step #2'} className={'tab-content-image'}/>
                                                    <img src={LARGE_LOCKER_INSTRUCTIONS_3_2x} alt={'48 Locker Instruction Step #3'} className={'tab-content-image'}/>
                                                    <img src={LARGE_LOCKER_INSTRUCTIONS_4_2x} alt={'48 Locker Instruction Step #4'} className={'tab-content-image'}/>
                                                    <img src={LARGE_LOCKER_INSTRUCTIONS_5_2x} alt={'48 Locker Instruction Step #5'} className={'tab-content-image'}/>
                                                </div>
                                            }
                                        </div>
                                },
                                {
                                    label: getLocalizedString('emergencyPortal.8', '8 Locker Kiosks'),
                                    labelContent:
                                        <div className={'tab-image-div'} style={isSmOrBelow ? {width: '30px', height: '25px', marginLeft: '10px'} : {}}>
                                            <img className={'tab-image'} alt={'Tab for instructions on 8 locker kiosks'} src={LOCKER_TAB_8}/>
                                        </div>
                                    ,
                                    content: 
                                        <div>
                                            {isSmOrBelow ?
                                                <div className={'u-display--flex u-flex-wrap'}>
                                                    <img src={SMALL_LOCKER_INSTRUCTIONS_1} alt={'8 Locker Instruction Step #1'} className={'tab-content-image'}/>
                                                    <img src={SMALL_LOCKER_INSTRUCTIONS_2} alt={'8 Locker Instruction Step #2'} className={'tab-content-image'}/>
                                                    <img src={SMALL_LOCKER_INSTRUCTIONS_3} alt={'8 Locker Instruction Step #3'} className={'tab-content-image'}/>
                                                    <img src={SMALL_LOCKER_INSTRUCTIONS_4} alt={'8 Locker Instruction Step #4'} className={'tab-content-image'}/>
                                                </div>
                                                :
                                                <div className={'u-display--flex u-flex-wrap'}>
                                                    <img src={SMALL_LOCKER_INSTRUCTIONS_1_2x} alt={'8 Locker Instruction Step #1'} className={'tab-content-image'}/>
                                                    <img src={SMALL_LOCKER_INSTRUCTIONS_2_2x} alt={'8 Locker Instruction Step #2'} className={'tab-content-image'}/>
                                                    <img src={SMALL_LOCKER_INSTRUCTIONS_3_2x} alt={'8 Locker Instruction Step #3'} className={'tab-content-image'}/>
                                                    <img src={SMALL_LOCKER_INSTRUCTIONS_4_2x} alt={'8 Locker Instruction Step #4'} className={'tab-content-image'}/>
                                                </div>
                                            }
                                        </div>
                                }
                            ]
                        }/>
                    </div>
                </div>
            </main>
        </AppWithFooter>
    )
}
