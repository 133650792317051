import {Button, IconButton, InputAdornment, Snackbar, TextField, useMediaQuery} from "@material-ui/core";
import {styled, useTheme} from "@material-ui/core/styles";
import * as React from 'react';
import {
    calculatedSizeMinBasedOnWidthHeightScaler,
    calculateResponsiveSizeWithMinMax
} from "../../util/TextUtils";
import {useNavigate, useParams} from 'react-router-dom';
import {PortalAccountDto} from "../../dtos/EmployeePortalDto";
import {getPasswordStrength} from "../../util/PasswordUtil";
import {
    addNewPortalAccount,
    appContextActions,
    appContextSelector, authorizeAsync, resendPortalInvite, validatePortalInviteToken
} from "../app-context/appContextSlice";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import ARCLOGO_BLACK from "../../assets/img/ARC_Logo_Black.png";
import {PasswordChecklist} from "../ui-components/PasswordChecklist";
import '../../css/NewPortalAccount.css';
import {HELP_EMAIL, HELP_PHONE, SYSTEM_EMAIL} from "../../constants/StringConstants";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import ARCLOGO_WHITE from "../../assets/img/ARC_Logo_White.png";
import {getLocalizedString} from "../../util/Localization";

export const NewPortalAccount = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const context = useAppSelector(appContextSelector);

    const {token} = useParams();

    React.useEffect(() => {
        if(token) {
            dispatch(validatePortalInviteToken({inviteToken: token}));
        }
    }, [token, dispatch]);

    const [newPortalAccountForm, setNewPortalAccountForm] = React.useState<PortalAccountDto>({
        username: '',
        password: '',
        confirmPassword: '',
        passwordStrength: 0
    });

    const submitForm = React.useCallback( async (event : React.FormEvent) => {
        event.preventDefault();

        const updatedPasswordForm = {
            ...newPortalAccountForm,
            passwordStrength: getPasswordStrength(newPortalAccountForm.password)
        }
        setNewPortalAccountForm(updatedPasswordForm);

        if(token) {
            await dispatch(addNewPortalAccount({portalAccountDto: updatedPasswordForm, inviteToken: token}))
        }

    }, [newPortalAccountForm, token, dispatch]);

    const login = React.useCallback(async () => {
        await dispatch(authorizeAsync({username: newPortalAccountForm.username, password: newPortalAccountForm.password, failedAttempts: 0}));
    }, [dispatch, newPortalAccountForm.password, newPortalAccountForm.username]);

    React.useEffect(() => {
        if(context.addPortalAccountSuccessful) {
            dispatch(appContextActions.resetAddNewPortal());

            login().then(() => navigate('/home'));
        }
    }, [dispatch, navigate, context.addPortalAccountSuccessful, login]);

    React.useEffect(() => {
        return () => {dispatch(appContextActions.resetAddNewPortal())}
    }, [dispatch]);

    return (
        <div className={'container'}>
            {context.addPortalAccountErrorMessage &&
            <Snackbar anchorOrigin={{vertical: 'top', horizontal: 'center'}} open={true} className={'login-snackbar'}>
                <div className={'login-snackbar-div'}>
                    <div style={{display: 'flex'}}>
                        <div className={'login-snackbar-text'}>
                            {context.addPortalAccountErrorMessage}
                        </div>
                    </div>
                </div>
            </Snackbar>
            }

            {context.inviteTokenInvalid ? <TokenInvalid/>
                :
                context.inviteTokenExpired ? <TokenExpired token={token}/>
                    :
                    context.inviteTokenUsed ? <TokenUsed/>
                        :
                        <CreateAccountForm newPortalAccountForm={newPortalAccountForm} setNewPortalAccountForm={setNewPortalAccountForm} onSubmit={submitForm}/>
            }

            <div className={'tool-tip'}>
                {getLocalizedString('support', 'ARC Support') + ' | '+ getLocalizedString('support.email', HELP_EMAIL) + ' | ' + getLocalizedString("support.phone", HELP_PHONE)}
            </div>
        </div>
    );
}

type CreateAccountFormProps = {
    newPortalAccountForm: PortalAccountDto,
    setNewPortalAccountForm: (newForm: PortalAccountDto) => void,
    onSubmit: (event: React.FormEvent) => void,
}

const CreateAccountForm:React.ComponentType<CreateAccountFormProps> = (props) => {
    const theme = useTheme();
    const isMdOrBelow = useMediaQuery(theme.breakpoints.down('md'));

    const [showPassword, setShowPassword] = React.useState<boolean>(false);
    const [showVerifyPassword, setShowVerifyPassword] = React.useState<boolean>(false);

    const passwordMismatch = React.useMemo(() => {
        return props.newPortalAccountForm.confirmPassword?.length > 0 ? props.newPortalAccountForm.password !== props.newPortalAccountForm.confirmPassword : false;
    }, [props.newPortalAccountForm.confirmPassword, props.newPortalAccountForm.password])

    const disableLogin = React.useMemo(() => {
        const username = props.newPortalAccountForm.username;
        const password = props.newPortalAccountForm.password;
        const confirmPassword = props.newPortalAccountForm.confirmPassword;

        let passwordsMatch = false;
        if(password && confirmPassword) {
            passwordsMatch = password === confirmPassword;
        }
        return !username || !password || !confirmPassword || !passwordsMatch;
    }, [props.newPortalAccountForm.confirmPassword, props.newPortalAccountForm.password, props.newPortalAccountForm.username]);

    return (
        <div className={'new-account-page-body'}>

            <div className={'new-account-header'}>
                <div className={'new-account-logo-div'}>
                    <img className={'login-logo'} src={ARCLOGO_BLACK}
                         alt="ARC Logo"/>
                    <div className={'new-account-logo-typography'}>{getLocalizedString('title', 'Client Portal')}</div>
                </div>
            </div>

            <br/>

            <form onSubmit={props.onSubmit} style={{width: '80%'}}>

                {!isMdOrBelow &&
                    <div className={'new-account-title'} style={{marginTop: '10px'}}>
                        {getLocalizedString('newPortalAccount.create', 'Create Account')}
                    </div>
                }

                <div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <div>
                        <NewPortalInputField
                            autoComplete={"username"}
                            placeholder={getLocalizedString('newPortalAccount.username', 'Create Username')}
                            InputProps={{
                                disableUnderline: true,
                                style: {
                                    marginTop: 0,
                                    height: '100%',
                                    fontSize: calculateResponsiveSizeWithMinMax('2', '18', '8'),
                                    paddingLeft: '15px',
                                    borderRadius: '12px'
                                }
                            }}
                            id="changepass-newpass"
                            className={'input-text'}
                            required={true}
                            onChange={(event) => {
                                props.setNewPortalAccountForm({
                                    ...props.newPortalAccountForm,
                                    username: event.target.value
                                })
                            }}
                        />

                    </div>

                    <div className={''}>
                        <NewPortalInputField
                            autoComplete={"create password"}
                            placeholder={getLocalizedString('newPortalAccount.password', 'Create Password')}
                            InputProps={{
                                disableUnderline: true,
                                endAdornment: (
                                    <InputAdornment
                                        position="end"
                                        style={{height: '50%'}}
                                    >
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setShowPassword(!showPassword)}
                                            edge="end"
                                            style={{height: '50%', width: '50%', fontSize: calculatedSizeMinBasedOnWidthHeightScaler('4')}}
                                        >
                                            {!showPassword ? (
                                                <Visibility fontSize="inherit"/>
                                            ) : (
                                                <VisibilityOff fontSize="inherit"/>
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                                style: {
                                    marginTop: 0,
                                    height: '100%',
                                    fontSize: calculateResponsiveSizeWithMinMax('2', '18', '8'),
                                    paddingLeft: '15px',
                                    borderRadius: '12px'
                                }
                            }}
                            id="changepass-newpass"
                            type={showPassword ? 'text' : 'password'}
                            className={'input-text'}
                            required={true}
                            onChange={(event) => {
                                props.setNewPortalAccountForm({
                                    ...props.newPortalAccountForm,
                                    password: event.target.value
                                })
                            }}
                        />
                    </div>

                    <div className={''}>
                        <NewPortalInputField
                            autoComplete={"verify password"}
                            placeholder={getLocalizedString('newPortalAccount.confirmPassword', 'Verify Password')}
                            InputProps={{
                                disableUnderline: true,
                                endAdornment: (
                                    <InputAdornment
                                        position="end"
                                        style={{height: '50%'}}
                                    >
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setShowVerifyPassword(!showVerifyPassword)}
                                            edge="end"
                                            style={{height: '50%', width: '50%', fontSize: calculatedSizeMinBasedOnWidthHeightScaler('4')}}
                                        >
                                            {!showVerifyPassword ? (
                                                <Visibility fontSize="inherit"/>
                                            ) : (
                                                <VisibilityOff fontSize="inherit"/>
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                                style: {
                                    marginTop: 0,
                                    height: '100%',
                                    fontSize: calculateResponsiveSizeWithMinMax('2', '18', '8'),
                                    paddingLeft: '15px',
                                    border: passwordMismatch ? '2px solid #C3483E' : '0',
                                    borderRadius: '12px'
                                }
                            }}
                            id="changepass-newpass"
                            type={showVerifyPassword ? 'text' : 'password'}
                            className={'input-text'}
                            required={true}
                            onChange={(event) => {
                                props.setNewPortalAccountForm({
                                    ...props.newPortalAccountForm,
                                    confirmPassword: event.target.value
                                })
                            }}
                        />
                    </div>
                </div>

                <div className={'password-mismatch-div'} style={{display: passwordMismatch ? 'inherit' : 'none'}}>
                    {passwordMismatch ?
                        <div className={'password-mismatch-text'}>
                            {getLocalizedString('newPortalAccount.passwordMismatch', 'Passwords do not match')}
                        </div>
                        :
                        <span className={'password-mismatch-text'}>
                                                    <br/>
                                                </span>
                    }
                </div>

                <PasswordChecklist password={props.newPortalAccountForm.password}/>

                <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                    <CreateAccountButtonLg
                        disabled={disableLogin}
                        id="adduser-save"
                        variant='contained'
                        type='submit'
                    >
                        {getLocalizedString('newPortalAccount.submit', 'Create Account and Login')}
                    </CreateAccountButtonLg>
                </div>
            </form>
        </div>
    )
}

type TokenExpiredProps = {
    token?: string
}

const TokenExpired : React.ComponentType<TokenExpiredProps> = ({token}) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const context = useAppSelector(appContextSelector);

    return (
        <>
        {
            !context.resendPortalInviteSuccessful ?
            <div className={'new-account-page-body-md'}>
                <div className={'new-account-header'}>
                    <div className={'new-account-logo-div'}>
                        <img className={'login-logo'} src={ARCLOGO_BLACK}
                             alt="ARC Logo"/>
                        <div className={'new-account-logo-typography'}>{getLocalizedString('title', 'Client Portal')}</div>
                    </div>
                </div>

                <br/>

                <>
                    <div className={'new-account-title'}>
                        {getLocalizedString('newPortalAccount.token.expired', 'This link has expired')}
                    </div>

                    <>
                        <div className={'new-account-subtitle-text'} style={{marginTop: '75px', textAlign: 'center', width: '90%', fontWeight: 'normal'}}>
                            {getLocalizedString('newPortalAccount.token.expired.sendNew', 'Click below to receive an email with a new invitation:')}
                        </div>

                        <div className={'new-account-button-row'}>

                            <CreateAccountButtonSm style={{color: '#FFFFFF', background: '#444444',}} onClick={() => navigate('/login')}>
                                {getLocalizedString('newPortalAccount.back', 'Back to Login')}
                            </CreateAccountButtonSm>

                            <CreateAccountButtonSm style={{marginTop: '40px'}} onClick={() => {
                                if(token) {
                                    dispatch(resendPortalInvite({inviteToken: token}));
                                }
                            }
                            }>
                                {getLocalizedString('newPortalAccount.token.expired.resend', 'Resend Link')}
                            </CreateAccountButtonSm>
                        </div>

                        <div className={'new-account-tip'}>
                            {getLocalizedString('newPortalAccount.token.expired.note', 'Note: This link will expire after 15 days.')}
                        </div>
                    </>
                </>
            </div>

            :

            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <img className={'new-account-logo-lg'} src={ARCLOGO_WHITE} alt="ARC Logo"/>

                <div className={'new-account-email-sent-text-div'}>
                    <div style={{maxWidth: '70vw'}} className={'new-account-email-sent-text new-account-email-sent-text-top'}>
                        {getLocalizedString('newPortalAccount.token.expired.inviteSent', 'You will receive an email from ' + SYSTEM_EMAIL + ' with a new invitation link', [SYSTEM_EMAIL])}
                    </div>
                    <div style={{maxWidth: '60vw'}} className={'new-account-email-sent-text new-account-email-sent-text-mid'}>
                        {getLocalizedString('newPortalAccount.token.expired.emailNotReceived', 'If you don’t receive an email, ask your manager to verify your email in the ARC Client Portal.')}
                    </div>
                    <div style={{maxWidth: '60vw'}} className={'new-account-email-sent-text new-account-email-sent-text-bottom'}>
                        {getLocalizedString('newPortalAccount.token.expired.contact', 'You can also contact ARC Support at ' + HELP_EMAIL + ' or ' + HELP_PHONE + ' for assistance.', [HELP_EMAIL, HELP_PHONE])}
                    </div>
                </div>
            </div>
            }
        </>
    )
}

const TokenUsed = () => {
    const navigate = useNavigate();

    return (
        <div className={'new-account-page-body-md'}>
            <div className={'new-account-header'}>
                <div className={'new-account-logo-div'}>
                    <img className={'login-logo'} src={ARCLOGO_BLACK}
                         alt="ARC Logo"/>
                    <div className={'new-account-logo-typography'}>{getLocalizedString('title', 'Client Portal')}</div>
                </div>
            </div>

            <br/>

            <>
                <div className={'new-account-title'}>
                    {getLocalizedString('newPortalAccount.token.used', 'This link has already been used')}
                </div>

                <div className={'new-account-subtitle'} style={{fontWeight: 'bold', marginTop: '30px'}}>
                    {getLocalizedString('newPortalAccount.token.forwarded', 'Did you use a link from a forwarded email?')}
                </div>

                <div className={'new-account-subtitle-text'} style={{marginTop: '20px'}}>
                    {getLocalizedString('newPortalAccount.token.unique', 'Links are unique to each individual and sent from ' + SYSTEM_EMAIL + '.', [SYSTEM_EMAIL])}
                </div>

                <div className={'new-account-subtitle-text'} style={{marginTop: '0px'}}>
                    {getLocalizedString('newPortalAccount.token.askManager', 'If you don\'t see the email, ask your manager to add you to the ARC Client Portal.')}
                </div>

                <CreateAccountButton onClick={() => navigate('/login')}>
                    {getLocalizedString('newPortalAccount.toLogin', 'Go to Login Page')}
                </CreateAccountButton>
            </>
        </div>
    )
}

const TokenInvalid = () => {
    const navigate = useNavigate();

    return (
        <div className={'new-account-page-body-md'}>
            <div className={'new-account-header'}>
                <div className={'new-account-logo-div'}>
                    <img className={'login-logo'} src={ARCLOGO_BLACK}
                         alt="ARC Logo"/>
                    <div className={'new-account-logo-typography'}>{getLocalizedString('title', 'Client Portal')}</div>
                </div>
            </div>

            <br/>

            <>
                <div className={'new-account-title'}>
                    {getLocalizedString('newPortalAccount.token.invalid', 'This link is invalid')}
                </div>

                <div className={'new-account-subtitle'} style={{fontWeight: 'bold'}}>
                    {getLocalizedString('newPortalAccount.token.forwarded', 'Did you use a link from a forwarded email?')}
                </div>

                <div className={'new-account-subtitle-text'}>
                    {getLocalizedString('newPortalAccount.token.unique', 'Links are unique to each individual and sent from ' + SYSTEM_EMAIL + '.', [SYSTEM_EMAIL])}
                </div>

                <div className={'new-account-subtitle-text'} style={{marginTop: '0px'}}>
                    {getLocalizedString('newPortalAccount.token.askManager', 'If you don\'t see the email, ask your manager to add you to the ARC Client Portal.')}
                </div>

                <CreateAccountButton onClick={() => navigate('/login')}>
                    {getLocalizedString('newPortalAccount.toLogin', 'Go to Login Page')}
                </CreateAccountButton>
            </>
        </div>
    )
}

const NewPortalInputField = styled(TextField) (({theme}) => ({
    height: '8vh',
    maxHeight: '60px',
    width: '40vw',
    minWidth: '0px !important',
    maxWidth: '550px',
    margin: '1.5vh 0 1.5vh 0',
    borderWidth: '0',
    [theme.breakpoints.down('xs')]: {
        height: '10vh',
        maxHeight: '29px',
        width: '70vw',
        minWidth: '0px !important',
        maxWidth: '264px',
    }
}));

const CreateAccountButtonSm = styled(Button) (({theme}) => ({
    height: '5vw',
    maxHeight: '60px',
    minWidth: '0px !important',
    maxWidth: '300px',
    margin: '50px 0px 30px 0px',
    padding: '0px 20px 0px 20px',
    borderRadius: '30px',
    borderWidth: '0',
    fontSize: '32px',
    fontWeight: 'bold',
    color: '#000000',
    background: '#FBBC41',
    letterSpacing: '0',
    textTransform: 'none',
    '&:hover': {
        backgroundColor: '#FBBC41',
        opacity: '.5'
    },
    [theme.breakpoints.down('md')]: {
        width: '35vw',
        height: '5vh',
        fontSize: '14px',
        margin: '40px 0px 20px 0'
    }
}));

const CreateAccountButton = styled(Button) (({theme}) => ({
    height: '5vw',
    maxHeight: '60px',
    width: '20vw',
    minWidth: '0px !important',
    maxWidth: '330px',
    margin: '50px 0px 30px 0px',
    padding: '0px 10px 0px 10px',
    borderRadius: '30px',
    borderWidth: '0',
    fontSize: '32px',
    fontWeight: 'bold',
    color: '#000000',
    background: '#FBBC41',
    letterSpacing: '0',
    textTransform: 'none',
    '&:hover': {
        backgroundColor: '#FBBC41',
        opacity: '.5'
    },
    [theme.breakpoints.down('md')]: {
        width: '40vw',
        height: '5vh',
        fontSize: '12px',
        margin: '40px 0px 30px 0'
    }
}));

const CreateAccountButtonLg = styled(Button) (({theme}) => ({
    height: '5vw',
    maxHeight: '61px',
    width: '30vw',
    minWidth: '0px !important',
    maxWidth: '437px',
    margin: '30px 0px 30px 0px',
    padding: '0px 10px 0px 10px',
    borderRadius: '30px',
    borderWidth: '0',
    fontSize: '32px',
    fontWeight: 'bold',
    color: '#000000',
    background: '#FBBC41',
    letterSpacing: '0',
    textTransform: 'none',
    '&:hover': {
        backgroundColor: '#FBBC41',
        opacity: '.5'
    },
    [theme.breakpoints.down('md')]: {
        fontSize: '18px'
    },
    [theme.breakpoints.down('xs')]: {
        width: '70vw',
        maxWidth: '192px',
        height: '5vh',
        maxHeight: '28px',
        fontSize: '12px',
        margin: '20px 0px 30px 0'
    }
}));
