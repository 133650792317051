import * as React from 'react';
import { ChangeEvent } from 'react';

type ARCTextAreaProps = {
    id: string,
    size: 'sm' | 'md',
    label?: string,
    placeholder?: string,
    required: boolean,
    disabled?: boolean;
    value: string | undefined,
    onChange: (event: ChangeEvent<HTMLTextAreaElement>) => void,
    validate?: (val: any) => string | undefined,
    className?: string,
    count?: string,
    redCount?: boolean
}

export const ARCTextArea : React.FC<ARCTextAreaProps> = (props) => {

    const [inputError, setInputError] = React.useState<string | undefined>(undefined);

    return (
        <div className={`o-textarea__container ${props.className ? props.className : ''}`}>
            {props.label &&
            <label className={`o-textarea__label--${props.size}`}>
                {props.label}

                {props.required &&
                <label className={'required-star'}>
                    {'*'}
                </label>
                }
            </label>
            }

            <textarea
                   disabled={props.disabled}
                   placeholder={props.placeholder ?? ''}
                   className={`o-text-area o-textarea--${props.size} ${inputError ? 'o-input__error' : ''}`}
                   value={props.value}
                   onChange={(event) => {
                       props.onChange(event);

                       if(props.validate) {
                           setInputError(props.validate(event.target.value));
                       }
                   }}
            />
            {props.count !== undefined &&
                <div className={`o-textarea__count ${props.redCount ? 'red' : ''}`}>
                    {props.count}
                </div>
            }
            {inputError &&
            <div className={'o-textarea__error'}>
                {inputError}
            </div>
            }


        </div>
    )
}