import * as React from 'react';

type ARCRadioInputProps = {
    id: string,
    size: 'sm' | 'md' | 'lg',
    label?: string,
    disabled?: boolean;
    required?: boolean;
    on: boolean;
    onChange: () => void;
    validate?: () => string | undefined;
    className?: string;
    style?: React.CSSProperties;
}

export const ARCRadioInput : React.FC<ARCRadioInputProps> = (props) => {
    const [inputError, setInputError] = React.useState<string | undefined>(undefined);

    return (
        <div className={'o-radio-button__container'}>
            <div className={`o-radio-button o-radio-button--${props.size} ${props.className || ''} ${props.disabled ? 'o-radio-button--disabled' : ''}`}
                 style={{...props.style}}
                 onClick={() => {
                     props.onChange();

                     if(props.validate) {
                         setInputError(props.validate());
                     }
                 }}>

                <div className={`o-radio-button__option o-radio-button__option--${props.size} ${props.on ? 'active' : ''}`}/>
            </div>

            {props.label &&
            <div className={'o-radio-button__label__container'}>
                <label className={`o-radio-button__label--${props.size}`}>
                    {props.label}

                    {props.required &&
                    <div className={'required-star'}>
                        {'*'}
                    </div>
                    }
                </label>

                {inputError &&
                <div className={'o-radio-button__error'}>
                    {inputError}
                </div>
                }
            </div>
            }
        </div>
    )
}