import * as React from 'react';
import {useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {appContextSelector} from "../../app-context/appContextSlice";
import AppBar from "../../appbar/AppBar";
import {SideBar} from "../../sidebar/SideBar";
import {deviceContextActions, deviceContextSelector, exportDevices} from "../deviceSlice";
import {AppWithFooter} from "../../../App";
import {FormTitle, ErrorMessageSectionItem} from "../../ui-components/StyledComponents";
import {ServerMessage} from "../../ui-components/ServerMessage";
import {getLocalizedString} from "../../../util/Localization";
import {ARCButton} from "../../ui-components/design-system/ARCButton";
import {ARCRadioInput} from "../../ui-components/design-system/ARCRadioInput";

export const ExportDevices = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const context = useAppSelector(appContextSelector);
    const deviceContext = useAppSelector(deviceContextSelector);

    const [activeOnly, setActiveOnly] = React.useState<boolean>(true);

    React.useEffect(() => {
        return () => {dispatch(deviceContextActions.resetExportDevice())}
    }, [dispatch]);

    React.useEffect(() => {
        if(deviceContext.exportFile) {
            const link = document.createElement('a');
            link.href = deviceContext.exportFile;
            link.setAttribute('download', 'DeviceExportFile.csv'); //or any other extension
            document.body.appendChild(link);
            link.click();
        }
    }, [deviceContext.exportFile])

    const submitForm = React.useCallback(() => {
        if(context.currentLocation?.locationId) {
            dispatch(exportDevices({locationId: context.currentLocation.locationId, activeOnly: activeOnly}));
        }
    }, [activeOnly, context.currentLocation?.locationId, dispatch]);

    return (
        <AppWithFooter>
            <AppBar/>
            <SideBar/>
            <main className={'o-main'}>
                <div className={'l-wrap l-container--lg'}>
                    {deviceContext.exportErrorMessage &&
                        <ServerMessage variant={'error'}>
                            <ErrorMessageSectionItem>
                                {deviceContext.exportErrorMessage}
                            </ErrorMessageSectionItem>
                        </ServerMessage>}

                    <FormTitle variant="h5" className={'o-heading--xl'}>{getLocalizedString('exportDevices.title', 'Export Devices')}</FormTitle>

                    <div className={'u-display--flex u-display--flex-column u-gap-sm'}>
                        <ARCRadioInput
                            id={'export-devices-active-only-radio'}
                            size={'md'}
                            on={activeOnly}
                            onChange={() => setActiveOnly(true)}
                            label={getLocalizedString('exportDevices.activeOnly', 'All active devices')}
                        />
                        <ARCRadioInput
                            id={'export-devices-all-radio'}
                            size={'md'}
                            on={!activeOnly}
                            onChange={() => setActiveOnly(false)}
                            label={getLocalizedString('exportDevices.all', 'All active, pending activation, and deactivated devices')}
                        />
                    </div>

                    <div className={'u-display--flex u-justify-content--flex-start u-align-items--base'} style={{marginTop: '40px'}}>
                        <ARCButton
                            fill={'filled'}
                            variant={'primary'}
                            size={'md'}
                            onClick={submitForm}
                            label={getLocalizedString('exportDevices.submit', 'Export')}
                        >
                        </ARCButton>
                        <ARCButton
                            fill={'outlined'}
                            variant={'secondary'}
                            size={'md'} 
                            onClick={() => {navigate(`/devices/view/locationId=${context.currentLocation?.locationId}/brandId=${context.currentBrand?.brandId}`)}}
                            label={getLocalizedString('cancel', 'Cancel')}
                        >
                        </ARCButton>
                    </div>
                </div>
            </main>
        </AppWithFooter>
    )
}